export function childrows() {
  $(() => {
    $(".table-childrows tbody").on("click", "td.details-control", (e) => {
      const $tr = $(e.target).closest("tr");
      const $row = $tr.next(".childrow");
      if (!$row.hasClass("d-none")) {
        $row.addClass("d-none");
        $tr.removeClass("shown");
      } else {
        $row.removeClass("d-none");
        $tr.addClass("shown");
      }
    });
  });
}
