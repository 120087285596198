var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import flatpickr from "flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
export function datePicker() {
  $(() => {
    flatpickr(".datepicker");
    const datePickerOptions = {
      locale: {
        weekdays: {
          shorthand: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
          longhand: [
            "Sonntag",
            "Montag",
            "Dienstag",
            "Mittwoch",
            "Donnerstag",
            "Freitag",
            "Samstag"
          ]
        },
        months: {
          shorthand: [
            "Jan",
            "Feb",
            "M\xE4r",
            "Apr",
            "Mai",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dez"
          ],
          longhand: [
            "Januar",
            "Februar",
            "M\xE4rz",
            "April",
            "Mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember"
          ]
        },
        firstDayOfWeek: 1,
        weekAbbreviation: "KW",
        rangeSeparator: " bis ",
        scrollTitle: "Zum \xC4ndern scrollen",
        toggleTitle: "Zum Umschalten klicken",
        time_24hr: true
      },
      disableMobile: true
    };
    const $datePicker = $(".datepicker");
    const configs = {
      dateFrom: __spreadProps(__spreadValues({}, datePickerOptions), {
        dateFormat: "Y-m-d",
        allowInput: true
      }),
      dateTo: __spreadProps(__spreadValues({}, datePickerOptions), {
        dateFormat: "Y-m-d",
        allowInput: true
      }),
      date: __spreadProps(__spreadValues({}, datePickerOptions), {
        allowInput: true,
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "M y",
            altFormat: "M y"
          })
        ]
      })
    };
    $datePicker.each((i, el) => {
      const $dataFilterArea = $(el).data("filter-area");
      flatpickr($(el), configs[$dataFilterArea] || {});
    });
  });
}
