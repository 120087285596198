export function tabmenu() {
  $(() => {
    const $tabnav = $(".tabnav");
    function inactivateTabs(el) {
      $(el).parent().parent().find("a.active").removeClass("active");
    }
    function setDocumentHashLocation(el) {
      let $deepLink = $(el).attr("data-deepLink");
      let url = document.location.toString();
      if ($deepLink === "") {
        $deepLink = $(el).attr("data-linkId") !== "" ? $(el).attr("data-linkId") : url.split("#")[1];
      }
      if ($deepLink.match("#")) {
        const array = $deepLink.split("#");
        const [, hashName] = array;
        $deepLink = hashName;
      }
      if (url.match("#")) {
        const array = url.split("#");
        const [urlName] = array;
        url = urlName;
      }
      document.location.href = `${url}#${$deepLink}`;
    }
    function scrollToTarget(target) {
      const $currentTabNav = target.closest(".tabnav");
      const $currentTabNavHeight = $currentTabNav.length > 0 ? $currentTabNav.height() : 0;
      $("body, html").animate({
        scrollTop: $currentTabNav.offset().top - ($currentTabNavHeight + 20)
      }, 300);
    }
    function checkUrlHash() {
      const url = document.location.toString();
      if (url.match("#")) {
        const deepLink = url.split("#")[1];
        const $deepLinkTarget = $(`.tabnav-item a[data-deepLink="${deepLink}"]`);
        if ($deepLinkTarget.length) {
          inactivateTabs($deepLinkTarget[0]);
          setDocumentHashLocation($deepLinkTarget[0]);
          $deepLinkTarget.tab("show");
          scrollToTarget($deepLinkTarget);
        } else {
          const $linkIdTarget = $(`.tabnav-item a[data-linkId="${deepLink}"]`);
          if ($linkIdTarget.length) {
            inactivateTabs($linkIdTarget[0]);
            setDocumentHashLocation($linkIdTarget[0]);
            $linkIdTarget.tab("show");
            scrollToTarget($linkIdTarget);
          }
        }
      }
    }
    $tabnav.each((i, el) => {
      const $tabnavList = $(el).find(".tabnav-list");
      jQueryBridget("flickity", flickity, $);
      $tabnavList.flickity({
        cellAlign: "left",
        contain: true,
        pageDots: false,
        groupCells: true,
        freeScroll: true,
        cellSelector: ".tabnav-item"
      });
      $(el).find(".tabnav-link").on("click", (e) => {
        inactivateTabs($(e.currentTarget));
        setDocumentHashLocation($(e.currentTarget));
      });
    });
    $(".modal").on("shown.bs.modal", (e) => {
      const $tabnavList = $(e.currentTarget).find(".tabnav-list");
      if ($tabnavList.length > 0) {
        $tabnavList.flickity("resize");
      }
    });
    $(window).on("hashchange", () => {
      checkUrlHash();
    });
    $(document).ready(() => {
      checkUrlHash();
    });
    $(window).on("resize", () => {
      const $tabnavList = $(".tabnav-list");
      if ($tabnavList.length > 0) {
        $tabnavList.flickity("resize");
      }
    });
  });
}
