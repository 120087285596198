import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "../../vendor/bodyScrollLock.es6";
export function megaMenu() {
  $(() => {
    const $megaMenu = $(".mega-menu");
    const $megaMenuLeft = $(".megamenu-left");
    const $btnMegaMenu = $('button[aria-controls="mega-menu-container"]');
    const $sidenavOverlay = $('<div id="sidenav-overlay"></div>');
    const $level1Li = $megaMenu.find(".level-1 > li");
    const $megaMenuContainer = $(".mega-menu-container");
    const $body = $("body");
    const $megaMenuMobileLevel1 = $(".mega-menu.mobile ul.level-1 li");
    const $level2 = $megaMenuLeft.find(".level-2");
    const $navContainer = $(".nav-container");
    $level2.each((i, el) => {
      $(el).children().first().addClass("nav-spacer");
    });
    function selectMenuItem(item) {
      const $that = item;
      const $dataItem = $that.data("nav-item");
      $level1Li.removeClass("active");
      $that.addClass("active");
      const $currentNavContainer = $navContainer.filter(`[data-nav-container="${$dataItem}"]`);
      const $backgroundImage = $currentNavContainer.data("bg");
      if ($backgroundImage) {
        $megaMenu.css("background-image", `url("${window.IMG_PATH + $backgroundImage}")`);
      }
    }
    function closeMegaMenu(item) {
      $sidenavOverlay.remove();
      $megaMenuContainer.removeClass("show");
      item.find("i").removeClass("close").addClass("menu");
      item.attr("aria-expanded", false);
      enableBodyScroll($megaMenu);
      clearAllBodyScrollLocks();
    }
    function megaMenuHeight() {
      const $headerHeight = $(".header").height();
      const $megaMenuHeight = window.innerHeight - $headerHeight;
      $megaMenu.css({
        "max-height": `${$megaMenuHeight}px`,
        "overflow-y": "auto"
      });
    }
    function hideOnClickOutside(selector) {
      const outsideClickListener = (event) => {
        const $target = $(event.target);
        if (!$target.closest(selector).length && $(selector).is(":visible")) {
          closeMegaMenu($btnMegaMenu);
          removeClickListener();
        }
      };
      const removeClickListener = () => {
        $(document).off("click", outsideClickListener);
      };
      $(document).on("click", outsideClickListener);
    }
    $btnMegaMenu.on("click", (e) => {
      e.preventDefault();
      const $self = $(e.currentTarget);
      if ($megaMenu.find(".level-1 > li.menupath").length > 0) {
        selectMenuItem($megaMenu.find(".level-1 > li.menupath"));
      } else {
        $megaMenu.find(".level-1 > li:first-child").addClass("active");
        selectMenuItem($megaMenu.find(".level-1 > li:first-child"));
      }
      if ($self.attr("aria-expanded") === "false") {
        const options = {
          reserveScrollBarGap: true,
          megaMenu: true
        };
        disableBodyScroll($megaMenu, options);
        $body.append($sidenavOverlay);
      } else {
        enableBodyScroll($megaMenu);
        clearAllBodyScrollLocks();
        $sidenavOverlay.remove();
      }
      $self.find("i").toggleClass("menu close");
      megaMenuHeight();
      $sidenavOverlay.on("click", () => closeMegaMenu($self));
    });
    $(document).on("click", (e) => {
      const $target = $(e.currentTarget);
      if (!$target.closest(".mega-menu-container").length && $megaMenuContainer.is(":visible")) {
        closeMegaMenu($btnMegaMenu);
      }
    });
    $('[data-toggle="dropdown"]').on("click", (e) => {
      const $target = $(e.currentTarget);
      if (!$target.closest(".mega-menu-container").length && $megaMenuContainer.is(":visible")) {
        closeMegaMenu($btnMegaMenu);
      }
    });
    $megaMenuContainer.on("click", (e) => {
      hideOnClickOutside(".mega-menu-container");
      e.stopPropagation();
    });
    $(window).on("resize", () => {
      if ($megaMenu.length > 0) {
        megaMenuHeight();
      }
    });
    $megaMenuMobileLevel1.find(".inside").on("click", (e) => {
      const $self = $(e.currentTarget).parent("li");
      const $parent = $self.parent("ul");
      $parent.find("li > ul").hide();
      $parent.find("i").removeClass("rotate-y");
      if ($self.hasClass("open")) {
        $self.find("> ul").hide();
        $self.removeClass("open");
      } else {
        $parent.find("li").removeClass("open");
        $self.find("> ul").show();
        $self.addClass("open");
        $self.find("i").first().addClass("rotate-y");
      }
    });
    $megaMenuMobileLevel1.find("ul.level-2 .nav-item").on("click", (e) => {
      const $self = $(e.currentTarget);
      const $parent = $self.parent("ul");
      $parent.find("li > ul").hide();
      $parent.find("i").removeClass("rotate-y");
      if ($self.hasClass("open-level2")) {
        $self.find("> ul").hide();
        $self.removeClass("open-level2");
      } else {
        $parent.find("li").removeClass("open-level2");
        $self.find("> ul").show();
        $self.addClass("open-level2");
        $self.find("i").first().addClass("rotate-y");
      }
    });
    $(".megamenu-right > .level-1").menuAim({
      submenuDirection: "left",
      activate: function(a) {
        const submenuData = $(a).data("nav-item");
        const $currentNavContainer = $navContainer.filter(`[data-nav-container="${submenuData}"]`);
        const $backgroundImage = $currentNavContainer.data("bg");
        $currentNavContainer.css("display", "block");
        $level1Li.removeClass("active");
        $(a).addClass("active");
        if ($backgroundImage) {
          $megaMenu.css("background-image", `url("${window.IMG_PATH + $backgroundImage}")`);
        }
      },
      deactivate: function(a) {
        const submenuData = $(a).data("nav-item");
        const $currentNavContainer = $navContainer.filter(`[data-nav-container="${submenuData}"]`);
        const $backgroundImage = $currentNavContainer.data("bg");
        $currentNavContainer.css("display", "none");
        $(a).removeClass("active");
        if ($backgroundImage) {
          $megaMenu.css("background-image", `url("${window.IMG_PATH + $backgroundImage}")`);
        }
      }
    });
  });
}
