export function iframe() {
  $(() => {
    function iFramesSrcSet() {
      $(".site iframe").each((i, el) => {
        const $iFrame = $(el);
        const $iFrameSrcSet = $iFrame.attr("srcset");
        const $iFrameSrc = $iFrame.attr("src");
        if ($iFrameSrcSet && $iFrameSrcSet !== "") {
          const sizes = $iFrameSrcSet.split(",");
          const currentWidth = $iFrame.parent().width();
          let newiFrameSrc = $iFrameSrc;
          for (let i2 = sizes.length - 1; i2 >= 0; i2 -= 1) {
            sizes[i2] = sizes[i2].trim();
            const breakpoints = sizes[i2].split(" ");
            if (breakpoints[1] >= currentWidth) {
              newiFrameSrc = breakpoints[0];
            }
          }
          $iFrame.attr("src", newiFrameSrc);
        } else {
          return false;
        }
      });
    }
    iFramesSrcSet();
    $("#button-open-xl-detailmenu").on("click", () => {
      iFramesSrcSet();
    });
    $(window).on("resize", () => {
      iFramesSrcSet();
    });
  });
}
