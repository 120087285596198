var __pow = Math.pow;
import Dropzone from "dropzone";
const pdfjsLib = require("pdfjs-dist");
pdfjsLib.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry");
export function multiupload() {
  Dropzone.autoDiscover = false;
  const $multiUpload = $(".multiupload");
  const $messageList = $(".multiupload .message-list");
  const $errorList = $(".multiupload .error-list");
  const $errorMessages = $errorList.find(".error-messages");
  const $errorFilesCount = $errorList.find(".files-count");
  const $successList = $(".multiupload .success-list");
  const $successMessages = $successList.find(".success-messages");
  const $successFilesCount = $successList.find(".files-count");
  const $uploadProgress = $(".multiupload .uploadprogress");
  const $uploadProgressFiles = $uploadProgress.find(".progress-files");
  const $uploadProgressStatus = $uploadProgress.find(".progress-status");
  const $previewContainer = $(".multiupload .preview-container");
  const $userID = $multiUpload.find('input:hidden[name="globalid"]').val();
  const $uniqID = $multiUpload.find('input:hidden[name="uniqid"]').val();
  const $gpNr = $multiUpload.find('input:hidden[name="gpnr"]').val();
  const $ocrArt = $multiUpload.find('input:hidden[name="ocrart"]').val();
  let errorMessageHTML = "";
  let successMessageHTML = "";
  let fileTimeSending;
  let fileCount = 0;
  function successFilesCount() {
    return $previewContainer.find(".dz-success.dz-complete").length;
  }
  function errorFilesCount() {
    return $errorMessages.find(".row").length;
  }
  function pushErrorMessage(message, fileName) {
    errorMessageHTML = `<div class="col-sm-5 dz-filename"><i class="icomoon-icons filetype_pdf"></i><span class="d-block text-truncate">${fileName}</span></div><div class="col-sm">${message}</div>`;
    $errorMessages.append(`<div class="dropzone-template row">${errorMessageHTML}</div>`);
    if ($errorList.is(":hidden")) {
      $errorList.show();
    }
    $errorFilesCount.html(errorFilesCount());
  }
  function pushSuccessMessage(fileName, uuid) {
    successMessageHTML = `<div class="col-sm-10 dz-filename"><i class="icomoon-icons filetype_pdf"></i><span>${fileName}</span></div><div class="col-sm-2"><span class="d-block d-sm-none pr-1">Upload:</span><span data-uuid="${uuid}">Datum</span></div>`;
    $successMessages.append(`<div class="dropzone-template row">${successMessageHTML}</div>`);
    if ($successList.is(":hidden")) {
      $successList.show();
    }
  }
  function updateFile(fileName, time, uuid) {
    const timeAgo = Math.floor(time / 1e3);
    let timeAgoText = "";
    if (timeAgo === 0) {
      timeAgoText = "Gerade eben";
    } else {
      timeAgoText = `vor ${timeAgo} Sek.`;
    }
    $messageList.find(`[data-uuid='${uuid}']`).html(timeAgoText);
  }
  if ($multiUpload.length > 0) {
    const $mhkDropzone = new Dropzone(".dropzone", {
      acceptedFiles: ".pdf",
      parallelUploads: 10,
      timeout: 3e4,
      url: `${window.IHT_API_URL}`,
      createImageThumbnails: false,
      previewTemplate: `<div class="dropzone-template row border-bottom">
                <div class="col-5">
                    <i class="icomoon-icons filetype_pdf"></i>
                    <span class="dz-filename" data-dz-name></span>
                    <span class="dz-size" data-dz-size></span>
                </div>
                <div class="col">
                    Datum
                </div>
            </div>`,
      previewsContainer: ".preview-container",
      dictDefaultMessage: '<i class="material-icons cloud_upload"></i> <p>PDF Datei(en) hier hineinziehen oder Fl\xE4che anklicken</p>',
      dictResponseError: "Der Server hat den Rechnungsupload mit Status {{statusCode}} abgelehnt",
      dictMaxFilesExceeded: "Sie k\xF6nnen keine weiteren Dateien mehr hochladen",
      dictInvalidFileType: "Dieser Dateityp ist nicht erlaubt",
      dictRemoveFile: "Datei entfernen",
      headers: {
        "Cache-Control": null,
        "X-Requested-With": null
      },
      accept(file, done) {
        const fileReader = new FileReader();
        fileReader.onload = function() {
          const pdfData = new Uint8Array(this.result);
          const loadingTask = pdfjsLib.getDocument({
            data: pdfData
          });
          loadingTask.promise.then((pdf) => {
            const fileSizeKB = file.size / __pow(1024, 1);
            const fileSizeEachPage = fileSizeKB / pdf.numPages;
            if (fileSizeEachPage > 150) {
              pushErrorMessage("Zul\xE4ssige Dateigr\xF6\xDFe von 150 KB pro Seite \xFCberschritten", file.name);
            } else {
              done();
            }
          }, (reason) => {
            switch (reason.name) {
              case "PasswordException":
                pushErrorMessage("PDF Passwortgesch\xFCtzt", file.name);
                break;
              case "InvalidPDFException":
                pushErrorMessage("PDF besch\xE4digt", file.name);
                break;
              case "MissingPDFException":
                pushErrorMessage("Keine PDF-Datei", file.name);
                break;
              case "UnexpectedResponseException":
                pushErrorMessage("PDF besch\xE4digt", file.name);
                break;
              case "UnknownErrorException":
                pushErrorMessage("Unbekannter Fehler", file.name);
                break;
              default:
                pushErrorMessage("Unbekannter Fehler", file.name);
                break;
            }
          });
        };
        fileReader.readAsArrayBuffer(file);
      }
    });
    $mhkDropzone.on("sending", (file, xhr, formData) => {
      fileTimeSending = Date.now();
      fileCount += 1;
      if (fileCount !== "" && $ocrArt !== "" && $uniqID !== "" && $gpNr !== "") {
        formData.append("ocr_art", $ocrArt);
        formData.append("ocrart", $ocrArt);
        formData.append("uniqid", $uniqID);
        formData.append("userid", $userID);
        formData.append("gpnr", $gpNr);
      } else {
        pushErrorMessage("FormDaten konnten nicht mitgesendet werden. Bitte wenden Sie sich an einen Administrator.", file.name);
      }
      xhr.ontimeout = () => {
        pushErrorMessage("\xDCbertragung abgebrochen, da der Server nicht erreichbar ist", file.name);
      };
    });
    $mhkDropzone.on("success", (file, response) => {
      const parsedResponse = JSON.parse(response);
      setTimeout(() => {
        $successFilesCount.html(successFilesCount());
      }, 350);
      pushSuccessMessage(file.name, file.upload.uuid);
      if (typeof parsedResponse.info !== "undefined") {
        console.log(parsedResponse.info);
      }
    });
    $mhkDropzone.on("error", (file, errorMessage) => {
      if (errorMessage.error === void 0) {
        pushErrorMessage(errorMessage, file.name);
      } else {
        pushErrorMessage(errorMessage.error, file.name);
      }
    });
    $mhkDropzone.on("complete", (file) => {
      const completeTime = Date.now() - fileTimeSending;
      updateFile(file.name, completeTime, file.upload.uuid);
    });
    $mhkDropzone.on("totaluploadprogress", (uploadProgress) => {
      const acceptedFiles = $mhkDropzone.getAcceptedFiles();
      const rejectedFiles = $mhkDropzone.getRejectedFiles();
      const uploadingFiles = $mhkDropzone.getUploadingFiles();
      const activeFileCount = fileCount - uploadingFiles.length;
      $uploadProgress.css("display", "flex");
      $uploadProgressFiles.html(`\xDCbertrage Datei ${activeFileCount} von ${fileCount}`);
      $uploadProgressStatus.html(`${Math.round(uploadProgress)}%`);
      if (uploadProgress === 100) {
        $uploadProgressFiles.html(`<i class="material-icons check_circle_outline"></i> Erfolgreich \xFCbertragen (${acceptedFiles.length}) <i class="icomoon-icons info_outline"></i> Fehlgeschlagen (${rejectedFiles.length})`);
        $uploadProgressStatus.html("100% - Abgeschlossen");
      }
    });
  }
}
