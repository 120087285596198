var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { ajaxConnectionConfigurator } from "../tools/ajax-connection-configurator";
import axios from "axios";
export function benutzerverwaltung() {
  $(() => {
    const $userAdministration = $(".user-administration");
    const $userTable = $userAdministration.find(".table-ajax");
    const $userTableWrapper = $userAdministration.find(".dataTables_wrapper");
    const $userDeleteModal = $("#useradministration-delete-modal");
    const $userModal = $("#useradministration-edit-modal");
    const $branchDeleteModal = $("#userbranch-delete-modal");
    let clicked = false;
    let loading = false;
    $userModal.on("hidden.bs.modal", (e) => {
      $(e.currentTarget).find("form").validate().resetForm();
      $(e.currentTarget).find("form").trigger("reset");
      $(e.currentTarget).find(".invalid").removeClass("invalid");
      $(e.currentTarget).find(".gp-user input, .gp-user-privileges select").prop("disabled", false);
      $(e.currentTarget).find(".gp-user-privileges .selectbox").removeClass("is-disabled");
      $(e.currentTarget).find("h3.modal-title").text("Benutzer bearbeiten");
      $(e.currentTarget).find(".js-select-all").html('Alle ausw\xE4hlen <i class="icomoon-icons check_all"></i>');
      $(e.currentTarget).find(".tasks .form-check-input:not(:disabled)").prop("checked", false);
      $(e.currentTarget).find(".mdb-select").val("");
      $(e.currentTarget).find(".nav-link, .tab-pane, label").removeClass("active");
      clicked = false;
    });
    function validateForm(id) {
      const valid = $(id).validate().checkForm();
      const $saveUserBtn = $(id).find('[form-submit="useradministration-save-user"]');
      if (valid) {
        $saveUserBtn.removeAttr("disabled");
      } else {
        $saveUserBtn.attr("disabled", "disabled");
      }
    }
    function showLoader(isModal = false) {
      const location = isModal === true ? $userModal.find(".scrolling-content") : $userTableWrapper;
      const loaderHTML = '<div class="is-loading"><div class="spinner-border" role="status"><span class="sr-only">Daten werden geladen</span></div></div>';
      const showTimeout = setTimeout(() => {
        $(".is-loading").slideDown(500);
      }, 2e3);
      clearTimeout(showTimeout);
      $(".is-loading").slideUp(500);
      location.prepend(loaderHTML);
    }
    function updateSelect(selectElement, inputValue, state) {
      if (inputValue === "") {
        $(selectElement).find("option:first").prop("selected", true).change();
      } else if (state === "disabled") {
        $(selectElement).find(`option[value='${inputValue}']`).prop("selected", true).change();
        $(selectElement).parent(".select-wrapper").children("input.select-dropdown").attr("disabled", "disabled");
        $(selectElement).parent(".select-wrapper").children("input.select-dropdown").val(inputValue).trigger("change");
      } else {
        $(selectElement).find(`option[value='${inputValue}']`).prop("selected", true).change();
        $(selectElement).parent(".select-wrapper").children("input.select-dropdown").attr("disabled", false);
        $(selectElement).parent(".select-wrapper").children("input.select-dropdown").val(inputValue).trigger("change");
        $(selectElement).parent(".select-wrapper").find("ul.select-dropdown > li").removeClass("active");
        $(selectElement).parent(".select-wrapper").find(`li:contains("${inputValue}")`).addClass("active");
        $(selectElement).parent(".select-wrapper").find(`li:contains("${inputValue}") input`).prop("checked", true).trigger("click");
      }
    }
    function showMessage(message, type, isModal = false) {
      const location = isModal === true ? $userModal.find(".scrolling-content") : $userTableWrapper;
      const $message = location.find(".messages");
      const messageHTML = `<div class="messages ${type}"><ul><li>${message}</li></ul></div>`;
      if ($message.length > 0) {
        $message.remove();
        location.prepend(messageHTML);
        setTimeout(() => {
          location.find(".messages").remove();
        }, 5e3);
      } else {
        location.prepend(messageHTML);
        setTimeout(() => {
          location.find(".messages").remove();
        }, 5e3);
      }
    }
    function setUserStatus({ id, status }) {
      axios.patch(`${ajaxConnectionConfigurator.getHost()}/${window.PATH_USERMANAGEMENT}/${id}`, { isActive: !status }).then(() => $userTable.DataTable().ajax.reload()).catch(() => showMessage("Es ist ein Fehler aufgetreten. Der Benutzer konnte nicht deaktiviert werden.", "error"));
    }
    function deleteUser(globalID) {
      axios.delete(`${ajaxConnectionConfigurator.getHost()}/${window.PATH_USERMANAGEMENT}/${globalID}`).then(() => {
        $userTable.DataTable().ajax.reload();
        showMessage("Der User wurde erfolgreich gel\xF6scht.", "success");
      }).catch(() => showMessage("Es ist ein Fehler aufgetreten. Der Benutzer konnte nicht gel\xF6scht werden.", "error"));
    }
    function deleteUserBranch({ globalID, gpNr }) {
      axios.delete(`${ajaxConnectionConfigurator.getHost()}/${window.PATH_USERMANAGEMENT}/${globalID}/gpuserprivileges/${gpNr}`).then(() => {
        $userTable.DataTable().ajax.reload();
        showMessage("Die Filialberechtigung wurde erfolgreich gel\xF6scht.", "success");
      }).catch(() => showMessage("Es ist ein Fehler aufgetreten. Die Filialberechtigung konnte nicht gel\xF6scht werden.", "error"));
    }
    function getUserData({ userID, globalID, state, gpNr, modal }) {
      axios.get(`${ajaxConnectionConfigurator.getHost()}/${window.PATH_USERMANAGEMENT}/${userID}`).then((resp) => {
        const data = resp.data;
        const {
          firstname,
          lastname,
          email,
          isActive,
          gps
        } = data;
        const $userStatus = modal.find('.switch input[type="checkbox"]');
        if (state === "editUser") {
          modal.find("h3.modal-title").text("Stammdaten bearbeiten");
          modal.find(".gp-user-privileges").addClass("d-none");
        } else {
          modal.find(".gp-user-privileges").removeClass("d-none");
        }
        if (state === "editBranch") {
          modal.find("#branch-title").remove();
          modal.find("h3.modal-title").text("Filialberechtigung bearbeiten");
          axios.get(`${ajaxConnectionConfigurator.getHost()}/${window.PATH_USERMANAGEMENT}/${userID}/gpuserprivileges/${gpNr}`).then((resp2) => {
            $.each(resp2.data, (i, item) => {
              let userRole = "";
              let isAdmin = false;
              isAdmin = item.privileges.some((el) => el["nameId"] === "int_chef");
              isAdmin ? userRole = "admin" : userRole = "user";
              modal.find(".gp-user .selectbox").children(".select-wrapper").show();
              updateSelect(modal.find("#branches"), item["name"], "disabled");
              $.each(item.gps, () => {
                modal.find("#select-options-branches").prop("checked", true);
              });
              $.each(item.privileges, (index, privilege) => {
                modal.find(`.tasks input#${userRole}-${privilege["nameId"]}`).prop("checked", true);
              });
              modal.find(`.radio-button-tab-nav a.nav-link[data-radiotab-handler="${userRole}"]`).trigger("click");
              modal.find(`.radio-button-tab-nav input[type="radio"][data-radiotab-handler="${userRole}"]`).prop("checked", true).trigger("click");
              modal.find("#privilegeid").val(item["id"]);
            });
          }).catch(() => showMessage("Es ist ein Fehler aufgetreten. Die Filialberechtigung konnte nicht geladen werden.", "error", true));
          modal.find(".gp-user input, .gp-user select").prop("disabled", true);
          modal.find(".gp-user .selectbox").addClass("is-disabled");
        } else if (state === "newBranch") {
          modal.find("h3.modal-title").text("Neue Filiale(n) hinzuf\xFCgen");
          modal.find(".gp-user input, .gp-user select").prop("disabled", true);
          modal.find(".gp-user .selectbox").addClass("is-disabled");
          const $branchesCount = modal.find("#branches option:not(:disabled)").length;
          const $branchesSecondText = modal.find("#branches option:eq(1)").text();
          if ($branchesCount === 1) {
            updateSelect(modal.find("#branches"), $branchesSecondText);
          }
        }
        if (isActive) {
          $userStatus.prop("checked", true);
          $userStatus.parent().find(".on").show();
          $userStatus.parent().find(".off").hide();
        } else {
          $userStatus.prop("checked", false);
          $userStatus.parent().find(".on").hide();
          $userStatus.parent().find(".off").show();
        }
        let gpsGpNr = [];
        gpsGpNr = gps.map((gp) => gp.gpnr);
        modal.find('input:hidden[name="gps"]').val(JSON.stringify(gpsGpNr)).trigger("change");
        modal.find("#firstname").val(firstname).trigger("change");
        modal.find("#lastname").val(lastname).trigger("change");
        modal.find("#email").val(email).trigger("change");
        modal.find("#uid").val(userID);
        modal.find("#globalid").val(globalID);
        const $saveUserBtn = modal.find('[form-submit="useradministration-save-user"]');
        $saveUserBtn.attr("disabled", "disabled");
        modal.find("form").on("blur keyup change click", "input", () => {
          validateForm(modal.find("form"));
        });
      }).catch(() => showMessage("Es ist ein Fehler aufgetreten. Der Benutzer konnte nicht geladen werden.", "error", true));
    }
    function showBranches(user) {
      const $wrapper = $('<div class="spinner-border" role="status"><span class="sr-only">Daten werden geladen</span></div>');
      axios.get(`${ajaxConnectionConfigurator.getHost()}/${window.PATH_USERMANAGEMENT}/${user.id}/gpuserprivileges`).then((resp) => {
        var _a;
        let subTable = "";
        const branchesCount = (_a = resp.data.length) != null ? _a : Object.keys(resp.data).length;
        subTable += `<table class="subtable subtable-childrow">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Filiale</th>
                            <th>Rolle</th>
                            <th>Aufgabenfelder</th>
                            <th class="text-right">Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>`;
        $.when($.each(resp.data, (i, rowEl) => {
          subTable += `<tr>
                        <td></td>
                        <td><span class="branch">${rowEl.name}</span></td>
                        <td><span class="role">`;
          const isAdmin = rowEl.privileges.some((el) => el["nameId"] === "int_chef");
          subTable += `${isAdmin ? "Administrator" : "Nutzer"}`;
          subTable += `</span></td>
                        <td><span class="privileges">`;
          if (typeof rowEl.privileges !== "undefined") {
            const has = Object.prototype.hasOwnProperty;
            subTable += $.map(rowEl.privileges, (d) => {
              if (has.call(d, "description")) {
                return d.description;
              }
            }).join(", ");
          }
          subTable += `</span></td>
                        <td class="text-right text-nowrap">
                            <button type="button" class="btn btn-edit btn-lg" data-toggle="modal" data-target="#useradministration-edit-modal" data-id="${rowEl["id"]}" data-uid="${user["id"]}" data-globalid="${user["globalId"]}" data-gpnr="${rowEl["gpNr"]}" data-state="editBranch"><i class="material-icons edit"></i></button>
                            <button type="button" class="btn btn-delete btn-lg" data-toggle="modal" data-target="#userbranch-delete-modal" data-privilegeid="${rowEl["id"]}" data-globalid="${user["globalId"]}" data-gpnr="${rowEl["gpNr"]}" data-branch="${rowEl["name"]}" data-user="${user["lastname"]}, ${user["firstname"]}"><i class="material-icons delete_outline"></i></button>
                        </td>
                    </tr>`;
        })).then(() => {
          const $branchesTotalCount = $userModal.find("#branches option:not(:disabled)").length;
          const rest = $branchesTotalCount - branchesCount;
          let full = "";
          if ($branchesTotalCount === branchesCount) {
            full = "d-none";
          }
          const howMany = rest > 1 ? "n" : "";
          subTable += `</tbody>
                    <tfoot class="${full}">
                        <tr>
                            <td></td>
                            <td class="footertd text-left" colspan="4"><a href="#" class="iconlink" data-toggle="modal" data-target="#useradministration-edit-modal" data-uid="${user["id"]}" data-globalid="${user["globalId"]}" data-state="newBranch"><i class="material-icons library_add"></i><span>Weitere Filiale${howMany} hinzuf\xFCgen</span></a></td>
                        </tr>
                    </tfoot>
                </table>`;
          $wrapper.html(subTable).removeClass("spinner-border");
        });
      }).catch(() => showMessage("Es ist ein Fehler aufgetreten. Die Filialberechtigungen konnten nicht geladen werden.", "error"));
      return $wrapper;
    }
    function saveBranchData(userPrivileges, state) {
      const {
        gps,
        name,
        userId,
        privileges,
        gpNr,
        globalId
      } = userPrivileges;
      const hasChanged = state === "edit" ? "ge\xE4ndert" : "neu angelegt";
      const isEditBranch = state === "edit" ? `/${gpNr}` : "";
      loading = true;
      if (loading) {
        showLoader(true);
      }
      axios({
        method: state === "edit" ? "put" : "post",
        url: `${ajaxConnectionConfigurator.getHost()}/${window.PATH_USERMANAGEMENT}/${userId}/gpuserprivileges${isEditBranch}`,
        data: {
          globalId,
          gpNr,
          name,
          userId,
          privileges,
          gps
        }
      }).then(() => {
        loading = false;
        $userTable.DataTable().ajax.reload();
        showMessage(`Die Filialberechtigung wurde erfolgreich ${hasChanged}.`, "success");
        $userModal.modal("hide");
      }).catch(() => showMessage(`Es ist ein Fehler aufgetreten. Die Filialberechtigung konnte nicht ${hasChanged} werden.`, "error"));
    }
    function saveUserData(user, state) {
      const {
        isActive,
        firstName,
        lastName,
        email,
        userId,
        gps,
        gpNr,
        name,
        privileges
      } = user;
      const hasChanged = state === "edit" ? "ge\xE4ndert" : "neu angelegt";
      loading = true;
      if (loading) {
        showLoader(true);
      }
      axios({
        method: userId ? "put" : "post",
        url: `${ajaxConnectionConfigurator.getHost()}/${window.PATH_USERMANAGEMENT}/${userId}`,
        data: {
          isActive,
          firstname: firstName,
          lastname: lastName,
          email,
          gps
        }
      }).then((response) => {
        if (state === "new") {
          saveBranchData({
            globalId: response.data.globalId,
            gpNr,
            name,
            userId: response.data.userId,
            privileges,
            gps
          }, "new");
        } else {
          loading = false;
          $userTable.DataTable().ajax.reload();
          showMessage("Der Benutzer wurde erfolgreich ge\xE4ndert.", "success");
          $userModal.modal("hide");
        }
        const info = $userTable.DataTable().page.info();
        $("#dataTables_users_information").text(`${info.recordsTotal} Benutzer vorhanden`);
      }).catch(() => showMessage(`Es ist ein Fehler aufgetreten. Der Benutzer konnte nicht ${hasChanged} werden.`, "error", true));
    }
    function showBranch(branch) {
      const $tr = $(branch).closest("tr");
      const row = $userTable.DataTable().row($tr);
      if (row.child.isShown()) {
        row.child.hide();
        $tr.removeClass("shown");
      } else {
        row.child(showBranches(row.data()), "childrow").show();
        $tr.addClass("shown");
        if ($userTable.find(".childrow")) {
          $userTable.find('.childrow [data-toggle="tooltip"]').tooltip();
        }
      }
    }
    const setUserData = (user, state) => {
      switch (state) {
        case "newUser":
          saveUserData(user, "new");
          break;
        case "editUser":
          saveUserData(user, "edit");
          break;
        case "newBranch":
          saveBranchData(user, "new");
          break;
        case "editBranch":
          saveBranchData(user, "edit");
          break;
        default:
          break;
      }
    };
    if ($userTable.length > 0) {
      if (!$userTable.hasClass("table-childrows")) {
        $userTable.addClass("table-childrows");
      }
      $userTable.find("tbody").on("click", 'td:last-child [data-toggle="dropdown"]', (event) => {
        const $tr = $(event.currentTarget).closest("tr");
        const row = $userTable.DataTable().row($tr);
        const $button = $(event.currentTarget);
        const $userID = $button.data("uid");
        const isActive = row.data()["isActive"] ? "Deaktivieren" : "Aktivieren";
        let isAdmin = "";
        const getRoleFrom = (url) => __async(this, null, function* () {
          const result = yield axios.get(url);
          $.map(result.data, (d) => {
            const hasChef = d.privileges.flatMap(({ nameId }) => nameId.includes("int_chef") ? nameId : []);
            hasChef.length > 0 ? isAdmin = "disabled" : "";
          });
          const dropdownHtml = `<a href="#" data-action="useradministration-user-active" data-uid="${$userID}" data-globalid="${row.data()["globalId"]}" data-status="${row.data()["isActive"]}" class="dropdown-item ${isAdmin}">${isActive}</a><div class="dropdown-divider"></div>
<a href="#" data-toggle="modal" data-target="#useradministration-edit-modal" data-uid="${$userID}" data-globalid="${row.data()["globalId"]}" data-state="editUser" class="dropdown-item">Bearbeiten</a><div class="dropdown-divider"></div>
<a href="#" data-toggle="modal" data-target="#useradministration-delete-modal" data-uid="${$userID}" data-globalid="${row.data()["globalId"]}" data-user="${row.data()["lastname"]}, ${row.data()["firstname"]}" class="dropdown-item ${isAdmin}">L\xF6schen</a>`;
          $button.next().html(dropdownHtml);
          $button.next().find('[data-action="useradministration-user-active"]').on("click", (event2) => {
            event2.preventDefault();
            setUserStatus({
              id: $(event2.currentTarget).data("uid"),
              status: $(event2.currentTarget).data("status")
            });
          });
        });
        getRoleFrom(`${ajaxConnectionConfigurator.getHost()}/${window.PATH_USERMANAGEMENT}/${$userID}/gpuserprivileges`);
      });
      $userTable.find("tbody").on("click", "td:first-child:not(.childrow)", (event) => {
        showBranch(event.currentTarget);
      });
      $userModal.on("show.bs.modal", (event) => {
        const $button = $(event.relatedTarget);
        const $modal = $(event.currentTarget);
        const $userID = $button.data("uid");
        const $globalID = $button.data("globalid");
        const $state = $button.data("state") === null ? "newUser" : $button.data("state");
        const $gpNr = $button.data("gpnr");
        const $tasks = $modal.find(".tasks");
        const $saveUserBtn = $modal.find('[form-submit="useradministration-save-user"]');
        clicked = false;
        $saveUserBtn.attr("disabled", "disabled");
        if ($modal.find(".is-loading")) {
          $modal.find(".is-loading").remove();
        }
        $modal.find(".permissions .radio-button-tab-nav a.nav-link[data-radiotab-handler]").on("click", (e) => {
          const $handler = $(e.currentTarget).data("radiotab-handler");
          $modal.find(`.permissions .radio-button-tab-nav input[type="radio"][data-radiotab-handler="${$handler}"]`).prop("checked", true).trigger("click");
        });
        if ($tasks.length && !$modal.find(".js-select-all").length) {
          $tasks.prepend('<button class="float-right btn-flat btn-trans text-primary js-select-all pt-1 pr-0">Alle ausw\xE4hlen <i class="icomoon-icons check_all"></i></button>');
          const $selectAllBtn = $modal.find(".js-select-all");
          $selectAllBtn.on("click", (e) => {
            e.preventDefault();
            clicked = $(e.currentTarget).parent(".tasks").find(".form-check-input:not(:disabled):checked").length === $(e.currentTarget).parent(".tasks").find(".form-check-input:not(:disabled)").length;
            $(e.currentTarget).parent(".tasks").find(".form-check-input:not(:disabled)").prop("checked", !clicked);
            clicked = !clicked;
            if (clicked) {
              $(e.currentTarget).html('Alle abw\xE4hlen <i class="icomoon-icons uncheck_all"></i>');
            } else {
              $(e.currentTarget).html('Alle ausw\xE4hlen <i class="icomoon-icons check_all"></i>');
            }
          });
        }
        if ($userID) {
          getUserData({
            globalID: $globalID,
            userID: $userID,
            state: $state,
            gpNr: $gpNr,
            modal: $modal
          });
        } else {
          $modal.find("h3.modal-title").text("Neuen Benutzer anlegen");
          $modal.find(".gp-user-privileges").removeClass("d-none");
          const $branchesCount = $modal.find("#branches option:not(:disabled)").length;
          const $branchesSecondText = $modal.find("#branches option:eq(1)").text();
          if ($branchesCount === 1) {
            updateSelect($modal.find("#branches"), $branchesSecondText);
          }
        }
        $modal.find("form").on("blur keyup change click", "input", () => {
          validateForm($modal.find("form"));
        });
        $saveUserBtn.off("click").on("click", (e) => {
          e.preventDefault();
          const $isAdminValue = $modal.find('.radio-button-tab-nav > .nav input[type="radio"]:checked').val();
          const disabled = $modal.find(".access-area :input:disabled").removeAttr("disabled");
          const $tasks2 = $modal.find(".tab-pane.active .access-area input:checked, .tab-pane.active .tasks input:checked").serializeArray();
          const privileges = [];
          disabled.attr("disabled", "disabled");
          $.each($tasks2, (i, privilege) => {
            privileges.push({
              nameId: privilege.name,
              description: privilege.value
            });
          });
          if ($isAdminValue === "tap-admin") {
            privileges.push({
              nameId: "int_chef"
            });
          }
          if ($modal.find("form").valid()) {
            let branches = [];
            if ($modal.find("#branches").val().length !== 0) {
              branches = $modal.find("#branches").val();
            } else {
              branches = JSON.parse($modal.find('input:hidden[name="gps"]').val());
            }
            setUserData({
              isActive: !!$modal.find('.switch input[type="checkbox"]').is(":checked"),
              salutation: "",
              firstName: $modal.find("#firstname").val(),
              lastName: $modal.find("#lastname").val(),
              email: $modal.find("#email").val(),
              userId: $state === "newUser" ? "" : $modal.find("#uid").val(),
              globalId: $state === "newUser" ? "" : $modal.find("#globalid").val(),
              gps: branches,
              name: $state === "editBranch" ? $modal.find("#branches").parent(".select-wrapper").children("input.select-dropdown:disabled").val() : $modal.find("#branches option:not([disabled]):selected").text(),
              privileges,
              privilegeId: $modal.find("#privilegeid").val(),
              gpNr: $state === "editBranch" ? $gpNr : $modal.find("#branches").val().join(",")
            }, $state);
          }
        });
      });
      $userDeleteModal.on("show.bs.modal", (event) => {
        const $modal = $(event.currentTarget);
        const $dropdownMenuLink = $(event.relatedTarget);
        const $userName = $dropdownMenuLink.data("user");
        const $globalID = $dropdownMenuLink.data("globalid");
        const $deleteUserBtn = $modal.find('[form-submit="useradministration-delete-user"]');
        $deleteUserBtn.attr("data-globalid", $globalID);
        $modal.find(".container span[data-name]").text($userName);
        $deleteUserBtn.off("click").on("click", (e) => {
          deleteUser($(e.currentTarget).attr("data-globalid"));
        });
      });
      $branchDeleteModal.on("show.bs.modal", (event) => {
        const $modal = $(event.currentTarget);
        const $dropdownMenuLink = $(event.relatedTarget);
        const $globalID = $dropdownMenuLink.data("globalid");
        const $userName = $dropdownMenuLink.data("user");
        const $branchName = $dropdownMenuLink.data("branch");
        const $gpNr = $dropdownMenuLink.data("gpnr");
        const $deleteBranchBtn = $modal.find('[form-submit="userbranch-delete-branch"]');
        $deleteBranchBtn.attr("data-globalid", $globalID);
        $deleteBranchBtn.attr("data-gpnr", $gpNr);
        $modal.find(".container span[data-name]").text($userName);
        $modal.find(".container span[data-branch]").text($branchName);
        $deleteBranchBtn.off("click").on("click", (e) => {
          deleteUserBranch({
            globalID: $(e.currentTarget).attr("data-globalid"),
            gpNr: $(e.currentTarget).attr("data-gpnr")
          });
        });
      });
    }
  });
}
