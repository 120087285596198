export function card() {
  $(() => {
    const $flipper = $(".flipper");
    const $flipperFront = $flipper.find(".front");
    const $flipperBack = $flipper.find(".back");
    const $flipperSubmit = $flipper.find('button[type="submit"]');
    const $rotateCard = $(".rotate-card");
    const $btnAjaxForgetPW = $('button[data-ajax="forgot-pw"]');
    function calcFlippContent() {
      if ($flipper.length > 0) {
        const $transition = $flipper.css("transition");
        $flipper.css("transition", "0s");
        $flipper.css("transition", $transition);
        if ($flipperFront.height() > $flipperBack.height()) {
          $flipper.css("height", `${$flipperFront.height()}px`);
        } else {
          $flipper.css("height", `${$flipperBack.height()}px`);
        }
      }
    }
    calcFlippContent();
    $flipperSubmit.on("click", () => {
      setTimeout(() => {
        calcFlippContent();
      }, 1);
    });
    $rotateCard.on("click", (e) => {
      $(e.currentTarget).parents(".card").toggleClass("flipped");
    });
    $btnAjaxForgetPW.on("click", () => {
      $(".js-forgot-pw-step1").hide();
      $(".js-forgot-pw-step2").show();
    });
    $(window).on("resize", () => {
      calcFlippContent();
    });
  });
}
