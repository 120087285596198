export function imageCrop() {
  $(() => {
    const $imageCrop = $(".image-crop");
    const $imageUploadField = $(".file-field--image");
    const $inputFile = $imageUploadField.find('input[type="file"]');
    const $modal = $("#imagecrop");
    const $cropSave = $modal.find("#cropsave");
    const $mdIcon = $imageUploadField.find(".material-icons");
    const $newImg = $imageUploadField.find(".file-field__cropimage");
    const $fileUploadText = $imageUploadField.find(".file-field__btn span");
    if ($imageCrop.length > 0) {
      let imgWidth = "200";
      let imgHeight = "200";
      let imgType;
      const cropImage = document.querySelector(".crop .crop-img");
      const newCropImage = document.querySelector(".file-field--image .file-field__cropimage");
      const input = document.querySelector(".file-field--image .file-field__file");
      let cropper;
      let cropBoxData;
      let canvasData;
      const dataWidth = document.querySelector(".crop [data-width]");
      const dataHeight = document.querySelector(".crop [data-height]");
      const options = {
        aspectRatio: 1,
        autoCropArea: 0.5,
        preview: ".crop-preview",
        viewMode: 1,
        crop(e) {
          const data = e.detail;
          dataHeight.textContent = Math.round(data.height) + " px";
          dataWidth.textContent = Math.round(data.width) + " px";
        },
        ready() {
          cropper.setCropBoxData(cropBoxData).setCanvasData(canvasData);
        }
      };
      $inputFile.on("change", (e) => {
        imgWidth = $(e.currentTarget).data("imgwidth");
        imgHeight = $(e.currentTarget).data("imgheight");
        imgType = $(e.currentTarget).data("imgtype");
        const files = e.target.files;
        const done = (url) => {
          input.value = "";
          cropImage.src = url;
          $modal.modal("show");
        };
        if (files && files.length > 0) {
          const targetFile = files[0];
          if (window.URL) {
            done(window.URL.createObjectURL(targetFile));
          } else if (FileReader) {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              done(fileReader.result);
            };
            fileReader.readAsDataURL(targetFile);
          }
        }
      });
      $modal.on("shown.bs.modal", (e) => {
        const $this = $(e.currentTarget);
        cropper = new Cropper(cropImage, options);
        const $cropActions = $this.find(".crop .js-crop-actions [data-method]");
        const $cropToggle = $this.find(".crop .js-crop-toggles input");
        $cropActions.removeClass("active");
        $cropToggle.parent().parent().find(".btn-crop").removeClass("active");
        $cropActions.on("click", (e2) => {
          e2.preventDefault();
          const $this2 = $(e2.currentTarget);
          const $data = $this2.data();
          $this2.parent().parent().find(".btn-crop").removeClass("active");
          $this2.addClass("active");
          if ($data.method) {
            cropper[$data.method]($data.option, $data.secondOption);
          }
        });
        $cropToggle.on("change", (e2) => {
          const $this2 = $(e2.currentTarget);
          const $name = $this2.attr("name");
          $this2.parent().parent().find(".btn-crop").removeClass("active");
          $this2.parent().addClass("active");
          options[$name] = $this2.val();
          cropper.destroy();
          cropper = new Cropper(cropImage, options);
        });
      }).on("hidden.bs.modal", () => {
        cropBoxData = cropper.getCropBoxData();
        canvasData = cropper.getCanvasData();
        cropper.destroy();
        cropper = null;
      });
      $(document).on("keydown", (e) => {
        switch (e.keyCode) {
          case 37:
            e.preventDefault();
            cropper.move(-10, 0);
            break;
          case 38:
            e.preventDefault();
            cropper.move(0, -10);
            break;
          case 39:
            e.preventDefault();
            cropper.move(10, 0);
            break;
          case 40:
            e.preventDefault();
            cropper.move(0, 10);
            break;
        }
      });
      $cropSave.on("click", () => {
        let initialProfileURL;
        let canvas;
        $modal.modal("hide");
        if (cropper) {
          canvas = cropper.getCroppedCanvas({
            width: imgWidth,
            height: imgHeight
          });
          initialProfileURL = newCropImage.src;
          newCropImage.src = canvas.toDataURL();
          canvas.toBlob((blob) => {
            const formData = new FormData();
            formData.append(imgType, blob, "profile.jpg");
            $.ajax("https://jsonserver:8080/user", {
              method: "POST",
              data: formData,
              processData: false,
              contentType: false,
              xhr: function() {
                return new XMLHttpRequest();
              },
              error: function() {
                newCropImage.src = initialProfileURL;
              }
            });
          });
        }
        $newImg.show();
        $mdIcon.hide();
        $fileUploadText.text("Bild bearbeiten");
      });
    }
  });
}
