import { ajaxConnectionConfigurator } from "../../tools/ajax-connection-configurator";
export function autosuggestSearch() {
  $(() => {
    const $headerInputSearch = $(".header-input-search");
    const $autoSuggestSearch = $(".autosuggestsearch");
    const $sidenavOverlay = $('<div id="sidenav-overlay"></div>');
    const $body = $("body");
    $headerInputSearch.on("focus", (e) => {
      const $self = $(e.currentTarget);
      if ($self.val().length > 0) {
        $self.trigger("keyup");
      }
    });
    $('[data-handler="reset-searchfield"]').on("click", (e) => {
      $(e.currentTarget).css("visibility", "hidden");
      $headerInputSearch.val("").trigger("keyup");
    });
    function autosuggestSearchHeight() {
      const maxHeight = window.innerHeight - $autoSuggestSearch.offset().top;
      $autoSuggestSearch.css("max-height", maxHeight);
    }
    $headerInputSearch.on("keyup", (e) => {
      const $self = $(e.currentTarget);
      const templateHtml = {
        section: "<section>{{titleRow}}{{contentRows}}</section>",
        titleRow: '<div class="row title">{{title}}</div>',
        contentRow: '<a href="{{link}}"><div class="row">{{content}}</div></a>',
        twoCols: '<div class="col-8">{{col1}}</div><div class="col-4">{{col2}}</div>',
        threeCols: '<div class="col-4">{{col1}}</div><div class="col-4">{{col2}}</div><div class="col-4">{{col3}}</div>'
      };
      function hideAutosuggestSearch() {
        $body.removeClass("search-is-open");
        $sidenavOverlay.remove();
        $autoSuggestSearch.hide();
      }
      if (!$self.val() === "") {
        $('[data-handler="reset-searchfield"]').css("visibility", "visible");
      }
      if ($self.val().length < 3) {
        hideAutosuggestSearch();
        return false;
      }
      const $searchString = $self.val();
      $.ajax({
        url: `${ajaxConnectionConfigurator.getHost()}/${window.PATH_AUTOSUGGEST}`,
        type: "GET",
        data: $searchString
      }).done((data) => {
        let HtmlReturn = "";
        let dataCounter = 0;
        const rowLimit = 3;
        const searchData = data.data;
        const linkAllSearchResults = data.link;
        for (const table in searchData) {
          let title = "";
          if (table === "inhalte") {
            title = templateHtml.twoCols.replace(/{{col1}}/g, "Inhalte").replace(/{{col2}}/g, "Anwendung");
          } else if (table === "seiten") {
            title = templateHtml.twoCols.replace(/{{col1}}/g, "Seiten, News & Anwendungen").replace(/{{col2}}/g, "Erstellt am");
          } else if (table === "dokumente") {
            title = templateHtml.twoCols.replace(/{{col1}}/g, "Dokumente").replace(/{{col2}}/g, "Hochgeladen am");
          } else if (table === "kontakte") {
            title = templateHtml.threeCols.replace(/{{col1}}/g, "Kontakte").replace(/{{col2}}/g, "E-Mail").replace(/{{col3}}/g, "Telefon");
          }
          const titleRow = templateHtml.titleRow.replace(/{{title}}/g, title);
          let contentRows = "";
          let rowCount = 0;
          searchData[table].forEach((row) => {
            if (rowCount < rowLimit) {
              let content = "";
              if (table === "inhalte") {
                content = templateHtml.twoCols.replace(/{{col1}}/g, `<span>${row.name}</span> <span>${row.role}</span>`).replace(/{{col2}}/g, row.anwendung);
              } else if (table === "kontakte") {
                content = templateHtml.threeCols.replace(/{{col1}}/g, `<span>${row.name}</span>`).replace(/{{col2}}/g, row.email).replace(/{{col3}}/g, row.tel);
              } else {
                content = templateHtml.twoCols.replace(/{{col1}}/g, `<span>${row.name}</span>`).replace(/{{col2}}/g, row.date);
              }
              const contentRow = templateHtml.contentRow.replace(/{{content}}/g, content).replace(/{{link}}/g, row.link);
              contentRows += contentRow;
              rowCount += 1;
            }
            dataCounter += 1;
          });
          HtmlReturn += templateHtml.section.replace(/{{titleRow}}/g, titleRow).replace(/{{contentRows}}/g, contentRows);
        }
        if ($(window).width() >= 576 && $(window).height() >= 500) {
          $body.addClass("search-is-open");
          $autoSuggestSearch.find(".searchtables").html(HtmlReturn);
          $autoSuggestSearch.show();
          const maxHeight = window.innerHeight - $autoSuggestSearch.offset().top;
          $autoSuggestSearch.css("max-height", maxHeight);
          $body.append($sidenavOverlay);
          $sidenavOverlay.css("background", "transparent");
          $('[data-info="searchresultcounter"]').html(dataCounter);
        }
        $('[data-link="allsearchresults"]').attr("href", linkAllSearchResults);
        $sidenavOverlay.off("click").on("click", () => {
          hideAutosuggestSearch();
        });
        $("button[data-action=search]").off("click").on("click", () => {
          hideAutosuggestSearch();
          window.location.href = linkAllSearchResults;
        });
        $(".header").off("click").on("click", (e2) => {
          if (!$(e2.target).closest(".header-input-search").length && !$(e2.target).closest(".autosuggestsearch").length) {
            hideAutosuggestSearch();
          }
        });
      });
    });
    $(window).on("resize", () => {
      if ($autoSuggestSearch.length > 0) {
        autosuggestSearchHeight();
      }
    });
  });
}
