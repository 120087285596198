export function tables() {
  $(() => {
    const $tableScrolling = $("table.scrolling");
    $tableScrolling.on("scroll", (e) => {
      const parentDiv = $(e.currentTarget).parent();
      if ($(e.currentTarget).scrollLeft() + $(e.currentTarget).innerWidth() >= $(e.currentTarget)[0].scrollWidth) {
        if (parentDiv.hasClass("scroll-right")) {
          parentDiv.removeClass("scroll-right");
        }
      } else if ($(e.currentTarget).scrollLeft() === 0) {
        if (parentDiv.hasClass("scroll-left")) {
          parentDiv.removeClass("scroll-left");
        }
      } else {
        if (!parentDiv.hasClass("scroll-right")) {
          parentDiv.addClass("scroll-right");
        }
        if (!parentDiv.hasClass("scroll-left")) {
          parentDiv.addClass("scroll-left");
        }
      }
    });
    function tableScroll() {
      $tableScrolling.each((i, table) => {
        const scrollbarOuter = $(table).parent(".scrollbar-outer");
        if (table.offsetWidth < table.scrollWidth) {
          if (!scrollbarOuter.hasClass("scroll-right")) {
            scrollbarOuter.addClass("scroll-right");
          }
        } else {
          if (scrollbarOuter.hasClass("scroll-right") || scrollbarOuter.hasClass("scroll-left")) {
            scrollbarOuter.removeClass("scroll-right scroll-left");
          }
        }
      });
    }
    $(document).ajaxComplete(() => {
      tableScroll();
    });
    $(window).on("resize", () => {
      tableScroll();
    });
  });
}
