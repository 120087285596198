import GoogleMapsApi from "./googlemapsapi";
import { mhk } from "../../_config";
export function googlemaps() {
  $(() => {
    function geocodeAddress(geocoder, resultsMap, markers, bounds) {
      if (typeof customerAdress === "undefined" || customerAdress === "") {
        return false;
      }
      const address = `${customerAdress.street} ${customerAdress.plz} ${customerAdress.town} ${customerAdress.country}`;
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK") {
          resultsMap.setCenter(results[0].geometry.location);
          const marker = new google.maps.Marker({
            map: resultsMap,
            position: results[0].geometry.location,
            icon: `${window.IMG_PATH}/googlemapsicons/pin-home.png`
          });
          markers.push(marker);
          bounds.extend(marker.position);
        } else {
          alert("Geocode was not successful for the following reason: " + status);
        }
      });
    }
    if ($(".map-container").length > 0) {
      const gmapApi = new GoogleMapsApi(mhk.gmKey);
      const $dataMitNR = $('input[type="radio"][data-mitnr]');
      gmapApi.load().then(() => {
        let locations;
        let google2;
        if (window.locations === void 0 && typeof window.locations !== "object") {
          locations = [];
        } else {
          locations = window.locations;
        }
        if (window.google === void 0 && typeof window.google !== "object") {
          google2 = {};
        } else {
          google2 = window.google;
        }
        const arrLat = [];
        const arrLng = [];
        let i;
        for (i = 0; i < locations.length; i += 1) {
          arrLat.push(parseFloat(locations[i].lat));
          arrLng.push(parseFloat(locations[i].lng));
        }
        const minLat = Math.min(...arrLat);
        const maxLat = Math.max(...arrLat);
        const minLng = Math.min(...arrLng);
        const maxLng = Math.max(...arrLng);
        const options = {
          zoom: 8,
          center: new google2.maps.LatLng((minLat + maxLat) / 2, (minLng + maxLng) / 2),
          mapTypeId: google2.maps.MapTypeId.ROADMAP,
          mapTypeControl: false
        };
        if (document.getElementById("map_canvas") !== null) {
          const map = new google2.maps.Map(document.getElementById("map_canvas"), options);
          let infowindow = null;
          const bounds = new google2.maps.LatLngBounds();
          const markers = [];
          const geocoder = new google2.maps.Geocoder();
          const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          let labelIndex = 0;
          for (i = 0; i < locations.length; i += 1) {
            const marker = new google2.maps.Marker({
              position: new google2.maps.LatLng(locations[i].lat, locations[i].lng),
              map,
              title: locations[i].company,
              data: locations[i],
              icon: `${window.IMG_PATH}googlemapsicons/pin-standort.png`,
              label: { text: labels[labelIndex += 1 % labels.length], color: "white", fontWeight: "bold" }
            });
            bounds.extend(marker.position);
            (function(marker2, i2) {
              marker2.addListener("click", () => {
                if (infowindow) {
                  infowindow.close();
                }
                const contentString = `<b>${locations[i2].company}</b><br><br>${locations[i2].plz} ${locations[i2].town}`;
                infowindow = new google2.maps.InfoWindow({
                  content: contentString
                });
                infowindow.open(map, marker2);
                if ($dataMitNR.length > 0) {
                  $dataMitNR.each((i3, el) => {
                    const $mitnr = $(el).data("mitnr");
                    if ($mitnr === locations[i3].mitnr) {
                      $(this).prop("checked", true);
                    }
                  });
                }
              });
            })(marker, i);
            markers.push(marker);
          }
          geocodeAddress(geocoder, map, markers, bounds);
          $dataMitNR.on("click", (e) => {
            const $mitnr = $(e.currentTarget).data("mitnr");
            markers.forEach((el, i2) => {
              if (el.data.mitnr === $mitnr) {
                google2.maps.event.trigger(markers[i2], "click");
              }
            });
          });
        }
      });
    }
  });
}
