export function supplierConditions() {
  $(() => {
    function detailTable(conditions) {
      if (typeof conditions.subtable !== "undefined") {
        let subTable = "";
        $.each(conditions.subtable, (i, tableEL) => {
          $.each(tableEL, (i2, rowEl) => {
            const thTitle = i2 === "condition" ? "Konditionen" : "Sortimente, Preise & Aktionen";
            subTable += `<table class="subtable subtable-childrow">
                <thead>
                    <tr>
                        <th></th>
                        <th>G\xFCltige ${thTitle}</th>
                        <th>G\xFCltig ab<br>G\xFCltig bis</th>
                        <th>Berechtigungen</th>
                    </tr>
                </thead>
                <tbody>`;
            $.each(rowEl, (i3, tdEl) => {
              subTable += `<tr>
                            <td class="filetype"><i class="icomoon-icons filetype_${tdEl.fileType}"></i></td>
                            <td><span class="title">${tdEl.title}</span><br><span class="desc">${tdEl.desc}</span></td>
                            <td><span class="validStart">${tdEl.validStart}</span><br><span class="validEnd">${!tdEl.validEnd ? "bis auf Widerruf" : tdEl.validEnd}</span></td>
                            <td>`;
              $.map(tdEl.auth, (d) => {
                subTable += `<span class="notification-badge" data-toggle="tooltip" title="${d.desc}"><span>${d.value}</span></span>`;
              }).join(", ");
              subTable += `</td>
                        </tr>`;
            });
            subTable += `</tbody>
                             <tfoot>
                                <tr>
                                    <td class="footertd" colspan="4"><a href="#" class="iconlink" target="_blank"><span>Archivierte ${thTitle}</span><i class="icomoon-icons file_search"></i></a></td>
                                </tr>
                             </tfoot>
                </table>`;
          });
        });
        return subTable;
      } else {
        return '<div class="p-2">Keine Konditionen vorhanden</div>';
      }
    }
    const $tableSupplierConditions = $("table#supplierconditions");
    if ($tableSupplierConditions.length > 0) {
      const dataTableConditions = $tableSupplierConditions.DataTable();
      $tableSupplierConditions.find("tbody").on("click", "td:first-child:not(.childrow)", (e) => {
        const $tr = $(e.currentTarget).closest("tr");
        const row = dataTableConditions.row($tr);
        if (row.child.isShown()) {
          row.child.hide();
          $tr.removeClass("shown");
        } else {
          row.child(detailTable(row.data()), "childrow").show();
          $tr.addClass("shown");
          if ($tableSupplierConditions.find(".childrow")) {
            $tableSupplierConditions.find('.childrow [data-toggle="tooltip"]').tooltip();
          }
        }
      });
    }
  });
}
