import { ajaxConnectionConfigurator } from "../tools/ajax-connection-configurator";
export function formAjax() {
  $(() => {
    const $form = $("#contact_form");
    const $contactSubmitBtn = $form.find(":submit");
    function showMessage(message, type) {
      const messagesHTML = `<div class="messages ${type}"><ul><li>${message}</li></ul></div>`;
      $form.prepend(messagesHTML);
    }
    $contactSubmitBtn.on("click", (e) => {
      const formData = $form.serialize();
      if ($("#contact_form")[0].checkValidity()) {
        e.preventDefault();
        $contactSubmitBtn.attr("disabled", true);
        $.ajax(ajaxConnectionConfigurator.postAjaxRequest(`${$form.attr("action")}`, "POST", formData)).fail(() => showMessage("Es ist ein Fehler aufgetreten. Die Nachricht konnte nicht gesendet werden.", "error")).done(() => {
          showMessage("Ihre Nachricht wurde erfolgreich gesendet.");
          $form.find('input[type="text"], input[type="file"], input[type="hidden"], textarea').val("");
          $form.find("label").removeClass("active");
          $contactSubmitBtn.attr("disabled", false);
        });
      }
    });
  });
}
