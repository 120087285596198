export function showPassword() {
  $(() => {
    const $pwdGroup = $(".show-pwd");
    const $showPwdButton = $pwdGroup.find(".input-group-append");
    const $pwd = $pwdGroup.find(".form-control");
    function togglePwd(element) {
      const $pwdInput = element.parent(".show-pwd").find(".form-control");
      const $pwdIcon = element.find(".input-group-text > i");
      if ($pwdInput.attr("type") === "password") {
        $pwdInput.attr("type", "text");
        $pwdIcon.removeClass("visibility").addClass("visibility_off");
      } else {
        $pwdInput.attr("type", "password");
        $pwdIcon.removeClass("visibility_off").addClass("visibility");
      }
    }
    function toggleClass(element) {
      const $showPwd = element.parent(".show-pwd").find(".input-group-append");
      $showPwd.toggleClass("is-visible", element.val() !== "");
    }
    $showPwdButton.on("click", (e) => {
      e.preventDefault();
      togglePwd($(e.currentTarget));
    });
    $pwd.on("keyup focusout", (e) => {
      toggleClass($(e.currentTarget));
    }).focusout();
  });
}
