export function videoplayer() {
  $(() => {
    const $video = $(".video-wrapper");
    const $playBtn = $video.find("a");
    $playBtn.on("click", (e) => {
      e.preventDefault();
      const $self = $(e.currentTarget);
      const $videoWrapper = $self.parent().find(".responsive-video");
      const $iframe = $self.parent().find("iframe");
      const $videoSrc = $self.data("videourl");
      $iframe.attr("src", $videoSrc);
      $self.hide();
      $videoWrapper.show();
      $iframe.show();
    });
  });
}
