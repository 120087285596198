export function webdynproTabnav() {
  $(() => {
    const $webdynproPage = $(".webdynpropage");
    const $tabNavLink = $webdynproPage.find("a[data-iframeurl]");
    function showWebDynpro(name, firstItem = false) {
      const $tabContentID = name.attr("id");
      const $iFrameURL = name.data("iframeurl");
      const $tabContent = name.parents(".tabpage").find(`.tab-content [aria-labelledby="${$tabContentID}"]`);
      const $iFrame = $tabContent.find("iframe");
      if ($iFrame.attr("src") === "") {
        if (firstItem) {
          $tabContent.addClass("show active");
        }
        $iFrame.attr("src", $iFrameURL);
      }
    }
    if ($webdynproPage.length > 0) {
      $webdynproPage.find(".tab-content .tab-pane:first .iframe-wrap").prepend('<div class="spinner-border ml-5 mt-3"></div>');
      $(window).on("load", () => {
        const $firstTabNavItem = $webdynproPage.find(".tabnav-list .tabnav-item:first .tabnav-link");
        showWebDynpro($firstTabNavItem, true);
      });
      $tabNavLink.on("click", (e) => {
        showWebDynpro($(e.currentTarget));
      });
    }
  });
}
