// mdbootstrap
import '@macrocom/mdbootstrap-pro/dist/js/bootstrap.min';
//import '@macrocom/mdbootstrap-pro/dist/js/mdb.min';

import '@macrocom/mdbootstrap-pro/js/vendor/free/jquery.easing';
import '@macrocom/mdbootstrap-pro/js/vendor/free/velocity';
//import '@macrocom/mdbootstrap-pro/js/vendor/free/chart';
//import '@macrocom/mdbootstrap-pro/js/vendor/free/chartjs-datalabels';
import '@macrocom/mdbootstrap-pro/js/src/free/wow';
import '@macrocom/mdbootstrap-pro/js/src/free/scrolling-navbar';
import '@macrocom/mdbootstrap-pro/js/vendor/free/waves';
import '@macrocom/mdbootstrap-pro/js/src/free/forms-free';
import '@macrocom/mdbootstrap-pro/js/src/pro/preloading';
import '@macrocom/mdbootstrap-pro/js/src/pro/cards';
import '@macrocom/mdbootstrap-pro/js/src/pro/character-counter';
//import '@macrocom/mdbootstrap-pro/js/vendor/pro/toastr';
import '@macrocom/mdbootstrap-pro/js/src/pro/smooth-scroll';
import '@macrocom/mdbootstrap-pro/js/src/pro/buttons';
import '@macrocom/mdbootstrap-pro/js/src/pro/sidenav';
import '@macrocom/mdbootstrap-pro/js/src/pro/collapsible';
//import '@macrocom/mdbootstrap-pro/js/src/pro/range-input';
import '@macrocom/mdbootstrap-pro/js/src/pro/file-input';
import '@macrocom/mdbootstrap-pro/js/src/pro/dropdown/dropdown';
import '@macrocom/mdbootstrap-pro/js/src/pro/dropdown/dropdown-searchable';
import './vendor/material-select/material-select-view-renderer';
import './vendor/material-select/material-select-view';
import './vendor/material-select/material-select';
//import '@macrocom/mdbootstrap-pro/js/vendor/pro/picker';
//import '@macrocom/mdbootstrap-pro/js/vendor/pro/picker-date';
//import '@macrocom/mdbootstrap-pro/js/vendor/pro/picker-time';
//import '@macrocom/mdbootstrap-pro/js/vendor/pro/picker-date-time';
import '@macrocom/mdbootstrap-pro/js/vendor/pro/lightbox';
//import '@macrocom/mdbootstrap-pro/js/vendor/pro/scrollbar';
//import '@macrocom/mdbootstrap-pro/js/src/pro/chips';
import '@macrocom/mdbootstrap-pro/js/vendor/pro/ofi';
//import '@macrocom/mdbootstrap-pro/js/vendor/pro/jarallax';
//import '@macrocom/mdbootstrap-pro/js/vendor/pro/jarallax-video';
import '@macrocom/mdbootstrap-pro/js/src/pro/mdb-autocomplete';
import '@macrocom/mdbootstrap-pro/js/vendor/free/enhanced-modals';
//import '@macrocom/mdbootstrap-pro/js/src/free/treeview';
import '@macrocom/mdbootstrap-pro/js/vendor/free/bs-custom-file-input';
import '@macrocom/mdbootstrap-pro/js/src/pro/sticky';

// addons
import '@macrocom/mdbootstrap-pro/dist/js/addons/datatables.min';
//import 'datatables.net-fixedcolumns';
import '@macrocom/mdbootstrap-pro/dist/js/addons/masonry.pkgd.min';

import 'jquery-ui/ui/widgets/sortable';
import './vendor/jquery-ui-touch';
import './vendor/jquery-audio-player';
import './vendor/jquery.menu-aim';
import './vendor/jquery.scrollbar';
import './vendor/mdb-file-upload.min';
import './vendor/isotope.pkgd.min';

global.jQueryBridget = require('jquery-bridget');
global.flickity = require('./vendor/flickity');
global.validate = require('jquery-validation');
global.Cropper = require('cropperjs');
//global.chart = require('chart.js');
global.bsCustomFileInput = require('@macrocom/mdbootstrap-pro/js/vendor/free/bs-custom-file-input');
global.PhotoSwipe = require('photoswipe');
// eslint-disable-next-line camelcase
global.PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default');
global.axios = require('axios');
global.flatpickr = require('flatpickr');
global.isotope = require('./vendor/isotope.pkgd.min');
