var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { ajaxConnectionConfigurator } from "../tools/ajax-connection-configurator";
import axios from "axios";
export function archiveBills() {
  $(() => {
    const $autocomplete = $("input.autocomplete");
    $autocomplete.each((i, el) => {
      const getNameFrom = (url) => __async(this, null, function* () {
        const result = yield axios.get(url);
        const autocompleteData = [];
        $.map(result.data, (d) => {
          autocompleteData.push(d.name);
        });
        if (autocompleteData.length > 0) {
          $(el).mdbAutocomplete({
            inputFocusShadow: "none",
            data: autocompleteData
          });
          $(el).parent().find("ul.mdb-autocomplete-wrap").insertAfter($(el).parent().find("label"));
        }
      });
      getNameFrom(`${ajaxConnectionConfigurator.getHost()}/creditor/`);
    });
  });
}
