import { ajaxConnectionConfigurator } from "../tools/ajax-connection-configurator";
export function suchergebnisse() {
  $(() => {
    const $inputDataSearch = $('input[data-action="search"]');
    if ($inputDataSearch.length === 0) {
      return false;
    }
    let tableColumnsSearch;
    let stringNoResults;
    if (window.tableColumnsSearch === void 0) {
      tableColumnsSearch = [];
    } else {
      tableColumnsSearch = window.tableColumnsSearch;
    }
    if (window.stringNoResults === void 0) {
      stringNoResults = "";
    } else {
      stringNoResults = window.stringNoResults;
    }
    function GetURLParameter(sParam) {
      const sPageURL = window.location.search.substring(1);
      const sURLVariables = sPageURL.split("&");
      for (let i = 0; i < sURLVariables.length; i += 1) {
        const sParameterName = sURLVariables[i].split("=");
        if (sParameterName[0] === sParam) {
          return sParameterName[1];
        }
      }
    }
    function filterParams() {
      const filterObj = {};
      const areaArray = [];
      $('.filter-search input[type="text"]').each((i, el) => {
        const self = $(el);
        const area = self.data("filter-area");
        if (typeof area === "undefined")
          return false;
        if (typeof areaArray[area] === "undefined" && self.val().length !== 0) {
          areaArray[area] = self.val();
        }
      });
      $('.filter-search input[type="checkbox"]:checked').each((i, el) => {
        const self = $(el);
        const area = self.data("filter-area");
        if (typeof area === "undefined")
          return false;
        if (typeof area !== "undefined" && typeof areaArray[area] === "undefined") {
          areaArray[area] = [];
        }
        areaArray[area].push(self.attr("name"));
      });
      for (const index in areaArray) {
        filterObj[index] = areaArray[index];
      }
      return filterObj;
    }
    function loadSearchTables() {
      const tParams = filterParams();
      let searchString = "";
      if ($inputDataSearch.val().length > 0) {
        searchString = $inputDataSearch.val();
      } else {
        searchString = GetURLParameter("query");
      }
      if (typeof searchString === "undefined" || searchString.length === 0) {
        return false;
      }
      $(".header-input-search").val(searchString);
      $.ajax({
        url: `${ajaxConnectionConfigurator.getHost()}/${window.PATH_SEARCH}`,
        type: "GET",
        data: `searchstring=${searchString}&filter=${JSON.stringify(tParams)}`
      }).done((data) => {
        const tableDataSearch = [];
        let searchItemCounter = 0;
        $.each(data, (index, aCategory) => {
          if (typeof tableDataSearch[`${index}-all`] === "undefined") {
            tableDataSearch[`${index}-all`] = [];
          }
          if (typeof aCategory !== "undefined") {
            aCategory.forEach((element) => {
              if (typeof tableDataSearch[element.type] === "undefined") {
                tableDataSearch[element.type] = [];
              }
              tableDataSearch[element.type].push(element);
              tableDataSearch[`${index}-all`].push(element);
              searchItemCounter += 1;
            });
          }
          $("[data-count-search-results]").html(searchItemCounter);
        });
        $('table[data-set="search"]').each((i, el) => {
          const category = $(el).data("cat");
          if (typeof tableDataSearch[category] !== "undefined" && typeof tableColumnsSearch[category] !== "undefined") {
            $(el).DataTable({
              data: tableDataSearch[category],
              columns: tableColumnsSearch[category],
              paging: false,
              destro: true,
              bFilter: false,
              ordering: false
            });
            $(`[data-count-${category}]`).html(tableDataSearch[category].length);
          } else {
            $(el).html(stringNoResults);
            $(`[data-count-${category}]`).html("0");
          }
        });
      });
    }
    $(".filter-search .form-check label").on("click", () => {
      window.setTimeout(() => {
        loadSearchTables();
      }, 100);
    });
    $inputDataSearch.on("change", () => {
      window.setTimeout(() => {
        loadSearchTables();
      }, 100);
    });
    $('button[data-action="search"]').on("click", () => {
      loadSearchTables();
    });
    loadSearchTables();
  });
}
