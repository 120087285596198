export function imageFilterWall() {
  $(() => {
    jQueryBridget("isotope", isotope, $);
    const $imageWall = $(".imagewall.has-filters");
    const $imageWallContent = $imageWall.find(".imagewall__content");
    const $imageWallCategories = $imageWall.find(".imagewall__categories");
    const $imageWallSorting = $imageWall.find(".imagewall__sorting");
    const $imageWallMobileCategories = $imageWall.find(".selectbox select.mdb-select");
    const $imageWallMobileCategoriesItem = $imageWallMobileCategories.find("option");
    const $imageWallCategoriesItem = $imageWallCategories.find(".radiotab-item");
    const $grid = $imageWallContent.isotope({
      itemSelector: ".imagewall__item",
      layoutMode: "fitRows",
      getSortData: {
        name: "[data-title]",
        category: "[data-categories]",
        weight(itemElem) {
          const weight = $(itemElem).find(".weight").text();
          return parseFloat(weight.replace(/[\(\)]/g, ""));
        }
      }
    });
    const filterFns = {
      numberGreaterThan50() {
        const number = $(this).find(".number").text();
        return parseInt(number, 10) > 50;
      },
      ium() {
        const name = $(this).find(".name").text();
        return name.match(/ium$/);
      }
    };
    $imageWallCategories.on("click", "a", (e) => {
      const dot = $(e.currentTarget).attr("data-filter") !== "*" ? "." : "";
      let filterValue = `${dot}${$(e.currentTarget).attr("data-filter")}`;
      filterValue = filterFns[filterValue] || filterValue;
      $grid.isotope({ filter: filterValue });
    });
    $imageWallMobileCategories.on("change", (e) => {
      const dot = $(e.currentTarget).val() !== "*" ? "." : "";
      let filterValue = `${dot}${$(e.currentTarget).val()}`;
      filterValue = filterFns[filterValue] || filterValue;
      $grid.isotope({ filter: filterValue });
    });
    $imageWallSorting.on("click", 'input[type="radio"]', (e) => {
      const sortByValue = $(e.currentTarget).val();
      $grid.isotope({ sortBy: sortByValue });
    });
    $imageWallCategories.each((i, tabGroup) => {
      const $tabGroup = $(tabGroup);
      $tabGroup.on("click", "a", (e) => {
        $tabGroup.find(".active").removeClass("active");
        $(e.currentTarget).addClass("active");
      });
    });
    $imageWallCategoriesItem.each((i, item) => {
      const $item = $(item);
      const $dataFilter = $item.find("a").data("filter");
      const $text = $item.find("a").text();
      if ($dataFilter !== "*") {
        const elems = $(`.${$dataFilter}`);
        $item.find("a").text(`${$text} (${elems.length})`);
      } else {
        $item.find("a").text(`${$text} (${$imageWallContent.find(".imagewall__item").length})`);
      }
    });
    $imageWallMobileCategoriesItem.each((i, item) => {
      const $item = $(item);
      const $dataFilter = $item.val();
      const $text = $item.text();
      if ($dataFilter !== "*") {
        const elems = $(`.${$dataFilter}`);
        $item.text(`${$text} (${elems.length})`).trigger("change");
      } else {
        $item.text(`${$text} (${$imageWallContent.find(".imagewall__item").length})`);
      }
    });
  });
}
