export function formvalidation() {
  $(() => {
    const $forms = $("form.needs-validation");
    if ($forms.length > 0) {
      jQuery.extend(jQuery.validator.messages, {
        required: "Bitte f\xFCllen Sie dieses Feld aus.",
        email: "Bitte geben Sie eine g\xFCltige E-Mail-Adresse ein",
        permissionTab: "Bitte w\xE4hlen Sie eine der genannten Rollen aus."
      });
      $forms.each((i, form) => {
        $(form).validate({
          errorElement: "div",
          errorClass: "invalid",
          errorPlacement(error, element) {
            if (element.is("select")) {
              element.parents(".selectbox").find(".invalid-feedback").empty().show();
              error.appendTo(element.parents(".selectbox").find(".invalid-feedback"));
            } else if (element.attr("name") === "tab") {
              element.parents(".radio-button-tab-nav").find(".tab-pane:last").empty().show();
              error.appendTo(element.parents(".radio-button-tab-nav").find(".tab-pane:last"));
            } else {
              element.parent().find(".invalid-feedback").empty().show();
              error.appendTo(element.parent().find(".invalid-feedback"));
            }
          },
          rules: {
            mail: {
              required: true,
              email: true
            },
            email: {
              required: true,
              email: true
            },
            tab: {
              required: true
            }
          }
        });
        $(form).find(".form-control").each((i2, inputElement) => {
          $(inputElement).rules("add", {
            messages: {
              required: $(inputElement).parent().find(".invalid-feedback .errormsg").text()
            }
          });
        });
      });
    }
  });
}
