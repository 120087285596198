export function modalboxes() {
  $(() => {
    function modalboxScroll(targetModal) {
      const scrollbarOuter = targetModal.find(".scrollbar-outside");
      const scrolling = targetModal.find(".scrolling-content");
      if (scrollbarOuter.outerHeight() < scrolling.height()) {
        if (!scrollbarOuter.hasClass("scroll-bottom")) {
          scrollbarOuter.addClass("scroll-bottom");
        }
      } else {
        if (scrollbarOuter.hasClass("scroll-bottom") || scrollbarOuter.hasClass("scroll-top")) {
          scrollbarOuter.removeClass("scroll-bottom scroll-top");
        }
      }
    }
    $(".modal").on("shown.bs.modal", (e) => {
      const $modalboxScrolling = $(e.currentTarget).find(".modal-body div.scrolling");
      $modalboxScrolling.on("scroll", (e2) => {
        const parentDiv = $(e2.currentTarget).parent();
        if ($(e2.currentTarget).scrollTop() + $(e2.currentTarget).innerHeight() >= $(e2.currentTarget)[0].scrollHeight) {
          if (parentDiv.hasClass("scroll-bottom")) {
            parentDiv.removeClass("scroll-bottom");
          }
        } else if ($(e2.currentTarget).scrollTop() === 0) {
          if (parentDiv.hasClass("scroll-top")) {
            parentDiv.removeClass("scroll-top");
          }
        } else {
          if (!parentDiv.hasClass("scroll-top")) {
            parentDiv.addClass("scroll-top");
          }
          if (!parentDiv.hasClass("scroll-bottom")) {
            parentDiv.addClass("scroll-bottom");
          }
        }
      });
      modalboxScroll($(e.currentTarget));
    });
    $(window).on("resize", () => {
      $(".modal:visible").each((i, modal) => modalboxScroll($(modal)));
    });
  });
}
