export function selectbox() {
  $(() => {
    const $selectbox = $(".mdb-select");
    $selectbox.materialSelect();
    $selectbox.on("change", (e) => {
      $(e.currentTarget).parent(".select-wrapper").next().removeClass("show");
    });
    $(".filter-muc .select-wrapper select").on("change", (e) => {
      const $gpNr = $(e.currentTarget).val();
      window.location.href = `/muc/${$gpNr}`;
    });
    $(".modal").on("shown.bs.modal", (e) => {
      const $modalBody = $(e.currentTarget).find(".modal-body");
      const $modalFooter = $(e.currentTarget).find(".modal-footer");
      const $selectWrapper = $modalBody.find(".select-wrapper");
      $selectWrapper.each((i, el) => {
        $(el).find(".select-dropdown").on("click", (e2) => {
          const $modalFooterTopPosition = $modalFooter[0].getBoundingClientRect().top;
          const $selectTopPosition = $(e2.currentTarget)[0].getBoundingClientRect().top;
          const $perfectPos = $modalFooterTopPosition - $selectTopPosition;
          const $dropdownSelect = $(e2.currentTarget).parent().find(".dropdown-content.select-dropdown");
          if ($perfectPos <= 150) {
            $dropdownSelect.addClass("dropup");
          } else {
            if ($dropdownSelect.hasClass("dropup")) {
              $dropdownSelect.removeClass("dropup");
            }
          }
        });
      });
    });
  });
}
