export function detailMenu() {
  $(() => {
    const $btnOpenDetailmenu = $("#button-open-detailmenu");
    const $detailMenu = $(".detailmenu");
    const $btnCloseDetailMenu = $(".button-collapse-detailmenu");
    const $listGroupItems = $detailMenu.find(".list-group > li");
    const $contentPlaceholder = $(".content-placeholder");
    const $contentLeftPlaceholder = $(".contentleft-placeholder");
    $btnOpenDetailmenu.SideBarNav({
      btnClose: $btnCloseDetailMenu,
      sidebar: $detailMenu,
      edge: "left"
    });
    $('button[data-handler="button-open-xl-detailmenu"]').on("click", (e) => {
      $contentLeftPlaceholder.toggleClass("hideleft");
      $contentPlaceholder.toggleClass("scale");
      $(e.currentTarget).find("span").toggleText("Anwendung vergr\xF6\xDFern", "Anwendung verkleinern");
      $(e.currentTarget).find("i").toggleClass("format_indent_decrease format_indent_increase");
      $contentPlaceholder.one("transitionend", () => {
        const $tabnavList = $(".tabnav-list");
        if ($tabnavList.length > 0) {
          $tabnavList.flickity("resize");
        }
      });
    });
    $listGroupItems.each((i, el) => {
      const $hasActiveChild = $(el).find("li.active");
      if ($hasActiveChild.length > 0) {
        $(el).addClass("active");
        $(el).find("a").addClass("active");
        $(el).find(".collapsible-body").show();
      }
    });
  });
}
