export function formSwitch() {
  $(() => {
    function handleSwitch(self) {
      if (self.is(":checked")) {
        self.parent().find(".on").show();
        self.parent().find(".off").hide();
      } else {
        self.parent().find(".on").hide();
        self.parent().find(".off").show();
      }
    }
    $(".switch").each((i, el) => {
      handleSwitch($(el).find('input[type="checkbox"]'));
    });
    $('.switch input[type="checkbox"]').on("click", (e) => {
      handleSwitch($(e.currentTarget));
    });
  });
}
