export function utilities() {
  $(() => {
    $('a[href*="#"]').not('[href="#"]').not("[data-toggle]").not('[role="tab"]').on("click", (e) => {
      const $headerHeight = $(".header").length > 0 ? $(".header").height() : 0;
      if (document.location.pathname.replace(/^\//, "") === e.currentTarget.pathname.replace(/^\//, "") && document.location.hostname === e.currentTarget.hostname) {
        let target = $(e.currentTarget.hash);
        target = target.length ? target : $(`[name=${e.currentTarget.hash.slice(1)}]`);
        if (target.length) {
          e.preventDefault();
          $("html, body").animate({
            scrollTop: target.offset().top - ($headerHeight + 20)
          }, 900, () => {
            const $target = $(target);
            $target.focus();
            if ($target.is(":focus")) {
              return false;
            }
            $target.attr("tabindex", "-1");
            $target.focus();
          });
        }
      }
    });
    $('[data-toggle="tooltip"]').tooltip();
    $('a[data-toggle="collapse"]').on("click", (e) => {
      $(e.currentTarget).find("i").toggleClass("rotate-y");
    });
  });
}
