export function cockpit() {
  $(() => {
    const $btnOpenSideNav = $("#button-open-sidenav");
    const $btnCloseSideNav = $("#js_button-collapse-side-nav");
    const $cockpit = $(".cockpit");
    $btnOpenSideNav.SideBarNav({
      btnClose: $btnCloseSideNav,
      sidebar: $cockpit,
      edge: "right"
    });
    $(window).on("scroll", () => {
      if ($(window).scrollTop() > 100) {
        $btnOpenSideNav.addClass("is-hidden");
      } else {
        $btnOpenSideNav.removeClass("is-hidden");
      }
    });
    const cockpitModuleBookedServices = $("#cockpitModuleBookedServices");
    if (cockpitModuleBookedServices.length > 0) {
      let hasSubModules = false;
      cockpitModuleBookedServices.find(".cockpitModules").each((i, el) => {
        if ($(el).html !== "") {
          hasSubModules = true;
        }
      });
      if (!hasSubModules) {
        cockpitModuleBookedServices.hide();
      }
    }
  });
}
