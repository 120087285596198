export function inputBtnClear() {
  $(() => {
    const $addBtnClear = $(".add-btn-clear");
    if ($addBtnClear.length > 0) {
      $addBtnClear.find(".form-control").on("keyup", (e) => {
        $(e.currentTarget).parent(".md-form").find("button.close-icon").toggleClass("visible", !!$(e.currentTarget).val().trim());
      });
      $addBtnClear.find(".close-icon").on("click", (e) => {
        $(e.currentTarget).parent(".md-form").find(".form-control").val("");
        $(e.currentTarget).removeClass("visible");
        $(e.currentTarget).parent(".md-form").find("label").removeClass("active");
      });
    }
  });
}
