import "./vendor";
import { utilities } from "./utilities";
import { sidebarnav } from "./components/navigation/sidebarnav";
import { sorting } from "./basics/tables/sorting";
import { selectbox } from "./components/form/selectbox";
import { fileInput } from "./components/form/file-input";
import { formSwitch } from "./components/form/switch";
import { datePicker } from "./components/form/date-picker";
import { nestedCheckboxes } from "./components/form/nested-checkboxes";
import { formvalidation } from "./components/form/formvalidation";
import { radioboxTabnav } from "./components/form/radiobox-tab-nav";
import { megaMenu } from "./components/navigation/megamenu";
import { cockpit } from "./components/cockpit/cockpit";
import { newsAccordion } from "./features/newsaccordion";
import { detailMenu } from "./components/navigation/detailmenu";
import { tabmenu } from "./components/navigation/tabmenu";
import { formGroups } from "./components/form/form-groups";
import { googlemaps } from "./components/googlemaps/googlemaps";
import { iframe } from "./basics/iframe/iframe";
import { card } from "./components/card/card";
import { ajaxTable } from "./features/ajax-table";
import { globalpages } from "./applications/globalpages";
import { audioplayer } from "./components/audioplayer/audioplayer";
import { videoplayer } from "./components/videoplayer/videoplayer";
import { btnFilter } from "./basics/buttons/btn-filter";
import { suchergebnisse } from "./features/suchergebnisse";
import { showPassword } from "./components/form/show-password";
import { validatePassword } from "./components/form/validate-password";
import { imageCrop } from "./components/image-crop/image-crop";
import { autosuggestSearch } from "./components/search/autosuggest-search";
import { benutzerverwaltung } from "./features/benutzerverwaltung";
import { webdynproTabnav } from "./features/webdynprotabnav";
import { formAjax } from "./features/form-ajax";
import { scrollbar } from "./basics/tables/scrollbar";
import { activeToggleDate } from "./components/form/active-toggle-date";
import { notificationBadge } from "./components/form/notification-badge";
import { inputBtnClear } from "./components/form/input-btn-clear";
import { childrows } from "./basics/tables/childrows";
import { supplierConditions } from "./features/supplier-conditions";
import { helpInfo } from "./features/help-info";
import { multiupload } from "./components/form/multiupload";
import { archiveBills } from "./features/archive-bills";
import { egpDatenvisu } from "./features/egp-datenvisu";
import { tables } from "./components/tables/tables";
import { modalboxes } from "./components/modal/modal";
import { imageFilterWall } from "./features/image-filter-wall";
utilities();
sidebarnav();
sorting();
selectbox();
fileInput();
datePicker();
formSwitch();
nestedCheckboxes();
megaMenu();
cockpit();
btnFilter();
newsAccordion();
detailMenu();
formvalidation();
tabmenu();
formGroups();
googlemaps();
iframe();
card();
globalpages();
ajaxTable();
audioplayer();
videoplayer();
suchergebnisse();
radioboxTabnav();
showPassword();
validatePassword();
imageCrop();
autosuggestSearch();
benutzerverwaltung();
webdynproTabnav();
scrollbar();
formAjax();
activeToggleDate();
notificationBadge();
inputBtnClear();
childrows();
supplierConditions();
helpInfo();
multiupload();
archiveBills();
egpDatenvisu();
tables();
modalboxes();
imageFilterWall();
