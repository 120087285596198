export function activeToggleDate() {
  $(() => {
    const $activeToggle = $(".active-toggle");
    if ($activeToggle.length > 0) {
      $(".active-toggle ul.select-dropdown li").on("click", (e) => {
        const $activeToggleActiveText = $(e.currentTarget).find("span").text();
        const $activeToggleState = $(e.currentTarget).parents(".select-wrapper").find(`option:contains("${$.trim($activeToggleActiveText)}")`).val();
        const $activeToggleIcons = $activeToggle.find(".col-icon .material-icons");
        const $activeToggleDateField = $activeToggle.find(".date-picker");
        const $activeToggleInput = $activeToggle.find("input.select-dropdown");
        $activeToggleIcons.attr("class", "material-icons");
        $activeToggleIcons.addClass($activeToggleState);
        if ($activeToggleInput.val() === "Aktiv ab") {
          $activeToggleDateField.removeClass("d-none");
        } else {
          if (!$(".active-toggle .date-picker.d-none").length) {
            $activeToggleDateField.addClass("d-none");
          }
        }
      });
    }
  });
}
