export function notificationBadge() {
  $(() => {
    const $notificationBadge = $(".notification-badge");
    if ($notificationBadge.length > 0) {
      $notificationBadge.on("click", (e) => {
        $(e.currentTarget).tooltip("dispose");
        $(e.currentTarget).remove();
      });
    }
  });
}
