export function validatePassword() {
  $(() => {
    const $validatePwd = $(".js-validate-pwd");
    const $validatePwdForm = $validatePwd.find("form");
    const $pwdSubmit = $validatePwd.find('button[type="submit"]');
    const $pwdInputs = $validatePwd.find(".form-control");
    const $mdForm = $validatePwd.find(".col-sm-6 .md-form");
    if ($validatePwdForm.length > 0) {
      $pwdInputs.on("keyup focusout", () => {
        if ($validatePwdForm.valid()) {
          $pwdSubmit.prop("disabled", false);
        } else {
          $pwdSubmit.prop("disabled", true);
        }
      });
      $validatePwdForm.validate({
        errorElement: "div",
        errorClass: "has-error",
        errorPlacement(error, element) {
          element.parents(".md-form").find(".invalid-feedback").empty().show();
          error.appendTo(element.parents(".md-form").find(".invalid-feedback"));
        }
      });
      $mdForm.each((i, el) => {
        i += 1;
        $(el).addClass(`pwd-${i}`);
        if (i === 1) {
          $(el).find(".form-control").rules("add", {
            required: true,
            messages: {
              required: "Bitte geben Sie Ihr derzeitiges Passwort ein"
            }
          });
        }
        if (i === 2) {
          $(el).find(".form-control").rules("add", {
            required: true,
            minlength: 8,
            pwcheck: true,
            messages: {
              required: "Bitte geben Sie Ihr neues Passwort ein",
              minlength: "Das Passwort muss mindestens 8 Zeichen enthalten",
              pwcheck: "Das Passwort muss gro\xDFe wie auch kleine Buchstaben, Zahlen und Sonderzeichen enthalten"
            }
          });
        }
        if (i === 3) {
          $(el).find(".form-control").rules("add", {
            equalTo: `#${$(el).parent().prev().find(".pwd-2 .form-control").attr("id")}`,
            messages: {
              required: "Bitte best\xE4tigen Sie Ihr neues Passwort",
              equalTo: "Das Passwort stimmt nicht \xFCberein"
            }
          });
        }
      });
      $.validator.addMethod("pwcheck", (value) => /^[a-zäöüßA-ZÄÖÜ0-9!@#$%^&*()_=[\]{};':"\\|,.<>/?+-]+$/.test(value) && /[A-ZÄÖÜ]+/.test(value) && /[a-zäöüß]+/.test(value) && /[\d/]+/.test(value) && /[!@#$%^&*()_=[\]{};':"\\|,.<>/?+-]/.test(value) && /\S{8,}/.test(value));
    }
  });
}
