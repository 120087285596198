export function egpDatenvisu() {
  $(() => {
    function reverseFormatNumber(val) {
      const group = new Intl.NumberFormat("de-DE").format(1111).replace(/1/g, "");
      const decimal = new Intl.NumberFormat("de-DE").format(1.1).replace(/1/g, "");
      let reversedVal = val.replace(new RegExp(`\\${group}`, "g"), "");
      reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, "g"), ".");
      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    }
    function detailTable(article) {
      if (typeof article.details !== "undefined") {
        let subTable = "";
        subTable += `<table class="subtable subtable-childrow">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Artikel-Nr.</th>
                            <th>Artikelbezeichnung</th>
                            <th class="text-right">Menge</th>
                            <th>Einheit</th>
                            <th class="text-right">Preis</th>
                            <th class="text-right">Positionswert (Netto)</th>
                            <th class="text-right">Steuer</th>
                        </tr>
                    </thead>
                    <tbody>`;
        $.each(article.details, (i, rowEl) => {
          subTable += `<tr>
                        <td></td>
                        <td><span>${rowEl["Artikel-Nr"]}</span></td>
                        <td><span>${rowEl.Artikelbezeichnung}</span></td>
                        <td class="text-right"><span>${rowEl.Menge}</span></td>
                        <td><span>${rowEl.Mengeneinheit}</span></td>
                        <td class="text-right"><span>${new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR", minimumFractionDigits: 2 }).format(reverseFormatNumber(rowEl.Preis))}</span></td>
                        <td class="text-right"><span>${new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR", minimumFractionDigits: 2 }).format(reverseFormatNumber(rowEl.PositionswertNetto))}</span></td>
                        <td class="text-right"><span>${rowEl.Steuer} %</span></td>
                    </tr>`;
        });
        subTable += "</tbody></table>";
        return subTable;
      } else {
        return '<div class="p-2">Keine Artikel gefunden</div>';
      }
    }
    function filterData(searchData, filterName, filterID) {
      let $dateFrom = $(`.filter .${filterName} input[data-filter-area="dateFrom"]`).val();
      let $dateTo = $(`.filter .${filterName} input[data-filter-area="dateTo"]`).val();
      const invoiceDate = searchData[filterID] || 0;
      $dateFrom = $dateFrom.substring(6, 10) + $dateFrom.substring(3, 5) + $dateFrom.substring(0, 2);
      $dateTo = $dateTo.substring(6, 10) + $dateTo.substring(3, 5) + $dateTo.substring(0, 2);
      const invoiceDateSubString = invoiceDate.substring(6, 10) + invoiceDate.substring(3, 5) + invoiceDate.substring(0, 2);
      if ($dateFrom === "" && $dateTo === "") {
        return true;
      }
      if ($dateFrom <= invoiceDateSubString && $dateTo === "") {
        return true;
      }
      if ($dateTo >= invoiceDateSubString && $dateFrom === "") {
        return true;
      }
      return $dateFrom <= invoiceDateSubString && $dateTo >= invoiceDateSubString;
    }
    const $tableDataVisualization = $("table#datavisualization");
    if ($tableDataVisualization.length > 0) {
      const dataTable = $tableDataVisualization.DataTable();
      $(".searchable-table .st-searchfield .btn").on("click", (e) => {
        let searchValue = $(e.currentTarget).parent().find("input.form-control").val();
        searchValue = searchValue.replace(/\s+/g, "|");
        dataTable.search(searchValue, true, false).draw();
      });
      $(".filter .add-btn-clear .close-icon").on("click", () => {
        dataTable.columns().search("").draw();
      });
      $.fn.dataTable.ext.search.push((settings, searchData) => {
        const $creditor = $(".filter input.autocomplete").val();
        if ($creditor === "") {
          return true;
        }
        return $creditor.indexOf(searchData[3]) !== -1;
      });
      $.fn.dataTable.ext.search.push((settings, searchData) => {
        const $debitor = $(".filter input:checkbox:checked").map((index, item) => item.value).get();
        if ($debitor.length === 0) {
          return true;
        }
        return $debitor.indexOf(searchData[2]) !== -1;
      });
      $.fn.dataTable.ext.search.push((settings, searchData) => filterData(searchData, "invoicedate", 6));
      $.fn.dataTable.ext.search.push((settings, searchData) => filterData(searchData, "paymentdate", 7));
      $.fn.dataTable.ext.search.push((settings, searchData) => {
        const $fibu = $('.filter input[data-filter-area="date"]').val();
        if ($fibu === "") {
          return true;
        }
        return $fibu.indexOf(searchData[10]) !== -1;
      });
      $(".filter input").on("change", () => {
        dataTable.draw();
      });
      $tableDataVisualization.find("tbody").on("click", "td:first-child:not(.childrow):not(.dataTables_empty)", (e) => {
        const $tr = $(e.currentTarget).closest("tr");
        const row = dataTable.row($tr);
        if (row.child.isShown()) {
          row.child.hide();
          $tr.removeClass("shown");
        } else {
          row.child(detailTable(row.data()), "childrow").show();
          $tr.addClass("shown");
          if ($tableDataVisualization.find(".childrow")) {
            $tableDataVisualization.find('.childrow [data-toggle="tooltip"]').tooltip();
          }
        }
      });
    }
    $("#egp-datenvisu-modalnotes").on("show.bs.modal", (event) => {
      const $modal = $(event.currentTarget);
      const $note = $(event.relatedTarget).data("note");
      $modal.find(".modal-body .notes").empty();
      const noteHTML = `<div class="note-wrapper col-12 py-2">
                <div class="note-item">
                    <div class="note-body">
                        ${$note}
                    </div>
                </div></div>`;
      $modal.find(".modal-body .notes").html(noteHTML);
    });
  });
}
