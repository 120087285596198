export function sidebarnav() {
  class SideBarNav {
    constructor(element, options) {
      this.screenWidth = $(window).width();
      this.sidenavOverlay = $('<div id="sidenav-overlay"></div>');
      this.body = $("body");
      this.defaults = {
        egde: "left"
      };
      this.element = element;
      this.options = this.assignOptions(options);
      this.init();
    }
    init() {
      this.bindTouchEvents();
    }
    assignOptions(newOptions) {
      return $.extend({}, this.defaults, newOptions);
    }
    bindTouchEvents() {
      $(window).on("resize", () => {
        if (this.screenWidth >= 1200) {
          this.options.sidebar.css("top", "0");
        } else {
          this.setFilterPosition(this.options.sidebar);
        }
      });
      this.element.on("click", (e) => {
        e.preventDefault();
        const $breadcrumb = $(window).scrollTop() <= 24;
        this.setFilterPosition(this.options.sidebar, $breadcrumb);
        this.options.sidebar.addClass("is-open");
        this.body.append(this.sidenavOverlay);
        const $sidebar = this.options.sidebar;
        const translateX = this.options.edge === "left" ? "-240" : "240";
        this.options.sidebar.velocity({
          translateX: [0, translateX]
        }, {
          duration: 300,
          queue: false,
          easing: "easeOutQuad",
          complete() {
            $sidebar.css({
              transform: "none"
            });
          }
        });
        this.body.addClass("no-scrolling sidebar-is-open");
        this.sidenavOverlay.on("click", () => this.closeSidebar());
      });
      this.options.btnClose.on("click", (e) => {
        e.preventDefault();
        this.closeSidebar();
        this.sidenavOverlay.on("click", () => this.closeSidebar());
      });
    }
    closeSidebar() {
      this.options.sidebar.removeClass("is-open");
      const translateX = this.options.edge === "left" ? "-100%" : "100%";
      this.options.sidebar.velocity({
        translateX
      }, {
        duration: 200,
        queue: false,
        easing: "easeOutCubic"
      });
      this.body.removeClass("no-scrolling sidebar-is-open");
      this.sidenavOverlay.remove();
    }
    setFilterPosition(filter, breadcrumb = true) {
      const $header = $(".header");
      const $infobar = $(".infobar");
      const $headerHeight = $header.length > 0 ? $header.height() : 0;
      const $breadcrumbHeight = breadcrumb === true ? $infobar.height() : 0;
      const $breadcrumbPosition = $infobar.length > 0 ? $infobar.offset().top - $(window).scrollTop() : 0;
      const $balance = $headerHeight - $breadcrumbHeight;
      const $topPosition = $breadcrumbPosition - $balance;
      if ($breadcrumbPosition > $balance) {
        filter.css("top", `${$headerHeight + $topPosition}px`);
      } else {
        filter.css("top", `${$headerHeight + $breadcrumbHeight}px`);
      }
    }
  }
  $.fn.SideBarNav = function(options) {
    const $self = $(this);
    return this.each(() => {
      new SideBarNav($self, options);
    });
  };
}
