export const ajaxConnectionConfigurator = {
  getHost() {
    return window.REST_API_URL !== void 0 ? window.REST_API_URL : "";
  },
  formatData(data) {
    return JSON.stringify(data);
  },
  getAjaxRequest(el) {
    return {
      url: `${this.getHost()}/${$(el).data("source")}`,
      dataType: "json",
      type: "GET",
      dataSrc(json) {
        return json.data;
      }
    };
  },
  postAjaxRequest(url, type, data) {
    return {
      url,
      contentType: "application/json",
      type,
      data: this.formatData(data)
    };
  }
};
