export function globalpages() {
  $(() => {
    if ($(".site > .login").length > 0) {
      $(".header").find(".header-logo-placeholder").removeClass("header-logo-placeholder");
    }
    $(".dropdown-toggle").dropdown();
    $('[data-toggle="dropdown"]').parent().on("shown.bs.dropdown", () => {
      $("body").addClass("dropdown-is-open");
    }).on("hidden.bs.dropdown", () => {
      $("body").removeClass("dropdown-is-open");
    });
    function showHeaderUserInfo() {
      const $header = $(".header");
      const $headerUserInfo = $header.find(".header-user-info");
      if ($(window).scrollTop() > 100) {
        if ($(window).width() < 576) {
          $header.addClass("is-small");
          $headerUserInfo.slideUp();
        } else {
          $header.removeClass("is-small");
          $headerUserInfo.slideDown();
        }
      } else {
        if ($(window).width() < 576) {
          $header.removeClass("is-small");
          $headerUserInfo.slideDown();
        }
      }
    }
    function setHeaderShadow() {
      const $header = $(".header");
      if ($(window).scrollTop() > 28) {
        $header.addClass("has-shadow");
      } else {
        $header.removeClass("has-shadow");
      }
    }
    function setTopPosition() {
      const $headerHeight = $("header").height();
      const $infoBarHeight = $(".infobar").height();
      $(".site").css("padding-top", `${$headerHeight}px`);
      $("#button-open-sidenav").css("top", `${$headerHeight + $infoBarHeight + 6}px`);
      $("#button-open-detailmenu").css("top", `${$headerHeight + $infoBarHeight + 6}px`);
      $(".btn-filter").css("top", `${$headerHeight + $infoBarHeight + 6}px`);
    }
    setTopPosition();
    $(window).on("resize", () => {
      setTopPosition();
    });
    $(window).on("scroll", () => {
      setHeaderShadow();
      showHeaderUserInfo();
    });
    $('a[href="#top"]').on("click", (e) => {
      e.preventDefault();
      $("html, body").stop().animate({ scrollTop: 0 }, "slow");
    });
    $.fn.extend({
      toggleText(a, b) {
        return this.text(this.text() === b ? a : b);
      }
    });
    $("[data-background]").each((i, el) => {
      const $self = $(el);
      const $attr = $self.attr("data-background");
      $self.css("background", `url("${$attr}")`);
      $self.css("background-size", "cover");
    });
    $(".list-group-item").find(".js_delete-list-item").on("click", (e) => {
      $(e.currentTarget).parents(".list-group-item").remove();
    });
    $("#modalStaffProgramme").on("show.bs.modal", (e) => {
      const $button = $(e.relatedTarget);
      const $modal = $(e.currentTarget);
      $modal.find(".modal-body").load(`${$button.data("href")} .pagecontent .content-placeholder .container-fluid`);
      $modal.find(".modal-title").text($button.data("title"));
    }).on("hidden.bs.modal", (e) => {
      const $modal = $(e.currentTarget);
      $modal.find(".modal-body").empty();
      $modal.find(".modal-title").empty();
    });
    const $mdbLightboxUI = $("#mdb-lightbox-ui");
    if ($mdbLightboxUI.length > 0) {
      $mdbLightboxUI.appendTo(document.body);
    }
    const $pimpcoreNewsAccordionComp = $(".pimcore_area_news-accordion-component");
    if ($pimpcoreNewsAccordionComp.html() === "") {
      let resize = false;
      $pimpcoreNewsAccordionComp.each((i, el) => {
        if (resize) {
          $(el).parent().addClass("col-md-8 offset-md-2");
          resize = false;
        } else if ($(el).html() === "") {
          $(el).parent().remove();
          resize = true;
        }
      });
    }
    $(window).on("load", () => {
      setTimeout(() => {
        const autoFilled = $(".loginform input.form-control:-webkit-autofill");
        autoFilled.each((i, el) => {
          $(el).trigger("blur");
        });
      }, 200);
    });
  });
}
