export function fileInput() {
  $(() => {
    $(".file-path").on("click", (e) => {
      $(e.currentTarget).parents(".file-field").find(".btn input").trigger("click");
    });
    $(".file-upload").file_upload({
      messages: {
        default: "Neue Datei hier hineinziehen oder Fl\xE4che anklicken",
        replace: "Neue Datei hier hineinziehen oder Fl\xE4che anklicken",
        remove: "Entfernen",
        error: "Es ist ein Fehler aufgetreten"
      },
      error: {
        fileSize: "Die Dateigr\xF6\xDFe ist zu gro\xDF ({{ value }} max).",
        minWidth: "Die Bildbreite ist zu klein ({{ value }}}px min).",
        maxWidth: "Die Bildbreite ist zu gro\xDF ({{ value }}}px max).",
        minHeight: "Die Bildh\xF6he ist zu klein ({{ value }}}px min).",
        maxHeight: "Die Bildh\xF6he ist zu gro\xDF ({{ value }}px max).",
        imageFormat: "Dieses Bildformat ist nicht erlaubt (nur {{ value }}).",
        fileExtension: "Diese Datei ist nicht erlaubt (nur {{ value }})."
      },
      tpl: {
        wrap: '<div class="card card-body view file-upload"></div>',
        loader: '<div class="mask rgba-stylish-slight"></div>',
        message: '<div class="card-text file-upload-message"><i class="material-icons cloud_upload"></i><p>{{ default }}</p></div>',
        preview: '<div class="file-upload-preview"><span class="file-upload-render"></span><div class="file-upload-infos"><div class="file-upload-infos-inner"><p class="file-upload-infos-message">{{ replace }}</p></div></div></div>',
        filename: '<p class="file-upload-filename"><span class="file-upload-filename-inner"></span></p>',
        clearButton: '<button type="button" class="btn btn-primary btn-sm btn-danger waves-effect waves-light">{{ remove }}</button>',
        errorLine: '<p class="file-upload-error">{{ error }}</p>',
        errorsContainer: '<div class="file-upload-errors-container"><ul></ul></div>'
      }
    });
  });
}
