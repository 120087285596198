import Cookies from "js-cookie";
export function helpInfo() {
  $(() => {
    const $headerInfoEl = $(".headerinfoelement");
    const $headerInfoElInfoTeaser = $(".headerinfoelement .infoteaser");
    const $btnCloseInfo = $headerInfoElInfoTeaser.find(".close");
    if ($headerInfoEl.length > 0) {
      $headerInfoEl.each((i, el) => {
        const $infoTeaserID = $(el).find(".infoteaser").attr("data-id");
        if ($(el).parent().parent().hasClass("area-muc-detail")) {
          $(el).find(".infoteaser").addClass("d-none");
          $(el).find(".headline i").addClass("d-inline");
        } else {
          if (Cookies.get(`mhknet-${$infoTeaserID}`) === "infoteaser") {
            $(el).find(".infoteaser").removeClass("d-block");
            $(el).find(".headline i").addClass("d-inline");
          } else {
            $(el).find(".infoteaser").addClass("d-block");
            $(el).find(".headline i").removeClass("d-inline");
          }
        }
      });
      $btnCloseInfo.on("click", (e) => {
        const $infoTeaserID = $(e.currentTarget).parents(".infoteaser").attr("data-id");
        $(e.currentTarget).parents(".infoteaser").removeClass("d-block");
        $(e.currentTarget).parents(".headerinfoelement").find(".headline i").addClass("d-inline");
        Cookies.set(`mhknet-${$infoTeaserID}`, "infoteaser");
      });
      const $btnInfoOpenModal = $(".headerinfoelement .headline i, .infoteaser .teasercontent .textlink");
      $btnInfoOpenModal.on("click", (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget);
        const $modalHeaderInfo = $this.parents(".headerinfoelement").next(".modal");
        const $teaserContent = $this.parents(".headerinfoelement").find(".infoteaser .teasercontent").clone();
        const $teaserHeadline = $this.parents(".headerinfoelement").find(".headline .infoheader").html();
        const $modalTitle = $modalHeaderInfo.find(".modal-header .modal-title");
        if (!$modalTitle.html()) {
          $modalTitle.text($teaserHeadline);
        }
        $modalHeaderInfo.find(".modal-body").html($teaserContent);
        $modalHeaderInfo.find(".modal-body .teasercontent .textlink").addClass("d-none");
        $modalHeaderInfo.find(".modal-body .teasercontent-more").removeClass("d-none");
        $modalHeaderInfo.modal();
      });
    }
  });
}
