export function newsAccordion() {
  $(() => {
    const $newsAccordion = $(".newsaccordion");
    const $cardHeader = $newsAccordion.find(".card .card-header");
    const $accordionBody = $newsAccordion.find(".card .accordion-body");
    $newsAccordion.each((i, el) => {
      const $cardCount = $(el).find(".card").length;
      if ($cardCount < 2) {
        $(el).find(".card-header").addClass("has-no-toggle").removeAttr("data-toggle");
      }
    });
    if ($(document).width() < 640) {
      $cardHeader.removeClass("collapsed");
      $accordionBody.addClass("show");
    }
    $(window).on("resize", () => {
      if ($(document).width() < 640) {
        $cardHeader.removeClass("collapsed");
        $accordionBody.addClass("show");
      } else {
        $cardHeader.addClass("collapsed");
        $accordionBody.removeClass("show");
        $newsAccordion.each((i, el) => {
          $(el).find(".card-header").first().removeClass("collapsed");
          $(el).find(".accordion-body").first().addClass("show");
        });
      }
    });
  });
}
