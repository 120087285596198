export function formGroups() {
  $(() => {
    $("input:checkbox[data-group-trigger]").on("click", (e) => {
      const $that = $(e.currentTarget);
      const $group = $that.data("group-trigger");
      $("input:checkbox").each((i, el) => {
        if ($(el).data("group") === $group) {
          el.checked = $that.is(":checked");
        }
      });
    });
  });
}
