import { ajaxConnectionConfigurator } from "../tools/ajax-connection-configurator";
export function ajaxTable() {
  $(() => {
    function setDatavisualization() {
      return [
        {
          targets: 0,
          orderable: false,
          render() {
            return '<i class="material-icons keyboard_arrow_down"></i>';
          }
        },
        {
          targets: 1,
          orderable: false,
          data: null,
          createdCell(td) {
            $(td).addClass("col-button");
          },
          render(data, type, row) {
            if (row.files.length > 0) {
              let fileList = `<div class="dropright"><button type="button" class="btn btn-edit btn-lg" id="bv-edit-menu-${row["id"]}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="icomoon-icons filetype_pdf_multiple"></i></button>
                                <div class="dropdown-menu" aria-labelledby="bv-edit-menu-${row["id"]}">`;
              $.each(row.files, (i, file) => {
                fileList += `<a href="/file/${row["gpNr"]}/${row["id"]}/${file["FileDes"]}" data-uid="${file["FileID"]}" class="dropdown-item file-download" data-filetype="pdf">${file["FileDes"]}</a><div class="dropdown-divider"></div>`;
              });
              fileList += "</div></div>";
              return fileList;
            }
          }
        },
        {
          targets: 2,
          render(data, type, row) {
            if (!row["DebitorName"]) {
              return row["BetriebsName"];
            }
          }
        },
        {
          targets: 4,
          render(data, type, row) {
            if (!row["RechnungsNr"]) {
              return row["PaginierNr"];
            }
          }
        },
        {
          targets: 6,
          render(data, type, row) {
            return row["docDate"];
          }
        },
        {
          targets: 7,
          render(data, type, row) {
            return row["payDate"];
          }
        },
        {
          targets: 8,
          data: null,
          createdCell(td) {
            $(td).addClass("text-right");
          },
          render(data, type, row) {
            const str = row["DocDes"];
            const parts = str.split(" : ");
            return `${new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR", minimumFractionDigits: 2 }).format(parts[1])}`;
          }
        },
        {
          targets: 9,
          createdCell(td) {
            $(td).addClass("text-right");
          },
          render(data, type, row) {
            return row["numDetails"];
          }
        },
        {
          targets: 10,
          createdCell(td) {
            $(td).addClass("text-right");
          },
          render(data, type, row) {
            return row["periodeFibu"];
          }
        },
        {
          targets: 11,
          render(data, type, row) {
            if (row["Notiz"]) {
              return `<a href="#" data-toggle="modal" data-target="#egp-datenvisu-modalnotes" data-note="${row["Notiz"]}" class="textlink">Anzeigen</a>`;
            } else {
              return "keine Notiz";
            }
          }
        },
        {
          defaultContent: "-",
          targets: "_all"
        }
      ];
    }
    function setUsers() {
      return [
        {
          defaultContent: "-",
          targets: "_all"
        },
        {
          targets: "_all",
          createdCell(td, cellData, rowData) {
            if (rowData["isActive"] === false) {
              $(td).addClass("is-disabled");
            }
          }
        },
        {
          targets: 0,
          orderable: false,
          render() {
            return '<i class="material-icons keyboard_arrow_down"></i>';
          }
        },
        {
          targets: 1,
          orderable: false,
          render(data) {
            if (data === true) {
              return '<i class="icomoon-icons person_check"></i>';
            } else {
              return '<i class="icomoon-icons person_off"></i>';
            }
          }
        },
        {
          targets: 2,
          render(data, type, row) {
            return `${row["lastname"]}, ${row["firstname"]}`;
          }
        },
        {
          targets: -1,
          orderable: false,
          data: null,
          render(data, type, row) {
            return `<button type="button" class="btn btn-menu btn-lg" id="bv-edit-menu-${row["id"]}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-uid="${row["id"]}"><i class="material-icons more_vert"></i></button><div class="dropdown-menu dropdown-menu-right" aria-labelledby="bv-edit-menu-${row["id"]}"></div>`;
          }
        }
      ];
    }
    function setSupplierconditions() {
      return [
        {
          defaultContent: "-",
          targets: "_all"
        },
        {
          targets: 0,
          orderable: false,
          render() {
            return '<i class="material-icons keyboard_arrow_down"></i>';
          }
        },
        {
          targets: -1,
          orderable: false,
          createdCell(td) {
            $(td).addClass("col-button");
          },
          render() {
            return '<button type="button" class="btn btn-edit btn-lg"><i class="material-icons edit"></i></button>';
          }
        }
      ];
    }
    function setDefault() {
      return [
        {
          defaultContent: "-",
          targets: "_all"
        }
      ];
    }
    function columnDefsTables(tabledID) {
      var _a;
      return (_a = {
        datavisualization: setDatavisualization(),
        users: setUsers(),
        supplierconditions: setSupplierconditions()
      }[tabledID]) != null ? _a : setDefault();
    }
    function ajaxDatavisualization(el) {
      const restAPI = window.REST_API_URL !== void 0 ? window.REST_API_URL : "";
      return {
        url: `${restAPI}/${$(el).data("source")}`,
        data(d) {
          d.filter = {
            creditor: {
              value: $(".filter input.autocomplete").val()
            },
            debitor: {
              value: $(".filter input:checkbox:checked").map((index, item) => item.value).get()
            },
            docDate: {
              from: $('.filter .paymentdate input[data-filter-area="dateFrom"]').val(),
              to: $('.filter .paymentdate input[data-filter-area="dateTo"]').val()
            },
            payDate: {
              from: $('.filter .invoicedate input[data-filter-area="dateFrom"]').val(),
              to: $('.filter .invoicedate input[data-filter-area="dateTo"]').val()
            },
            fibu: {
              value: $('.filter input[data-filter-area="date"]').val()
            }
          };
          delete d.columns;
          return d;
        }
      };
    }
    function showLoader() {
      const loaderHTML = '<div class="spinner-border" role="status"><span class="sr-only">Daten werden geladen</span></div>';
      const showTimeout = setTimeout(() => {
        $(".spinner-border").slideDown(500);
      }, 2e3);
      clearTimeout(showTimeout);
      $(".spinner-border").slideUp(500);
      return loaderHTML;
    }
    $(".table-ajax").each((i, el) => {
      const tableCells = [];
      const $tableID = $(el).data("id");
      const $btnLoadMore = $(el).find(".td-loadmore .btn.loadmore");
      $(el).find("th").each((i2, element) => {
        if (typeof $(element).data("id") !== "undefined") {
          let $colName = $(element).data("id");
          if ($colName === "") {
            $colName = "default";
          }
          tableCells.push({
            data: $(element).data("id"),
            className: `col-${$colName.toLowerCase()}`
          });
        }
      });
      if ($tableID === "datavisualization") {
        tableCells.push({
          data: "details",
          visible: false,
          render(data) {
            const detailsData = [];
            $.each(data, (i2, rowEl) => {
              detailsData.push(rowEl["Artikel-Nr"]);
              detailsData.push(rowEl["Artikelbezeichnung"]);
            });
            return detailsData;
          }
        });
      }
      const table = $(el).DataTable({
        processing: true,
        serverSide: $tableID === "datavisualization",
        destroy: true,
        deferRender: $tableID !== "datavisualization",
        order: [],
        ajax: $tableID === "datavisualization" ? ajaxDatavisualization(el) : ajaxConnectionConfigurator.getAjaxRequest(el),
        columns: tableCells,
        pageLength: 10,
        language: {
          processing: showLoader(),
          emptyTable: "",
          zeroRecords: "",
          info: "_TOTAL_"
        },
        initComplete() {
          const $tableRowCount = parseInt($(`#${table.tables().nodes().to$().attr("id")}_info`).text(), 10);
          const isUserTable = $tableID === "users" ? "Benutzer" : "Datens\xE4tze";
          if ($tableID !== "datavisualization") {
            if ($tableRowCount > 0) {
              $(`#dataTables_${$tableID}_information`).text(`${$tableRowCount} ${isUserTable} vorhanden`);
            } else {
              $(`#dataTables_${$tableID}_information`).text(`Keine ${isUserTable} vorhanden`);
            }
          }
          if ($tableRowCount <= 10) {
            $btnLoadMore.hide();
          }
          const tableAPI = this.api();
          tableAPI.columns().every(function() {
            const $inputSearch = $("thead tr th.td-filter").eq(this.index()).find(".filter-wrapper .form-control").on("keyup", (e) => {
              this.search($(e.currentTarget).val()).draw();
              $(e.currentTarget).parents("form").find("button").toggleClass("visible", !!$(e.currentTarget).val().trim());
            });
            const state = this.state.loaded();
            if (state) {
              const inputValue = state.columns[this.index()];
              $inputSearch.val(inputValue.search.search);
            }
          });
        },
        drawCallback() {
          if (!$(this).parent().hasClass("table-responsive")) {
            $(this).wrap('<div class="table-responsive"></div>');
          }
          const $visibleRows = $(`#${$tableID} > tbody > tr:visible`).length;
          const $tableRowCount = parseInt($(`#${$tableID}_info`).text(), 10);
          if ($tableRowCount >= 10) {
            $btnLoadMore.show();
          }
          if ($visibleRows === $tableRowCount) {
            $btnLoadMore.hide();
          }
        },
        columnDefs: columnDefsTables($tableID)
      });
      $(el).find(".filter-wrapper .close-icon").on("click", (e) => {
        const $id = $(e.currentTarget).data("index");
        $(el).find(`.form-control[data-index="${$id}"]`).val("");
        $(e.currentTarget).removeClass("visible");
        table.columns().search("").draw();
      });
      table.columns.adjust().draw();
      $btnLoadMore.on("click", () => {
        const $visibleRows = $(`#${$tableID} > tbody > tr:visible`).length;
        const i2 = $visibleRows + 10;
        table.page.len(i2).draw();
      });
    });
    $('a[data-toggle="tab"]').on("shown.bs.tab", () => {
      $($.fn.dataTable.tables(true)).css("width", "100%");
      $($.fn.dataTable.tables(true)).DataTable().columns.adjust().draw();
    });
  });
}
