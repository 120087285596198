export function nestedCheckboxes() {
  $(() => {
    $(".nested-checkboxes").each((i, el) => {
      const $parentCheckbox = $(el).find('input[type="checkbox"][data-handler]');
      const $childCheckboxes = $(el).find('input[type="checkbox"]:not([data-handler])');
      $childCheckboxes.on("click", () => {
        const checkedCount = $childCheckboxes.filter(":checked").length;
        if (checkedCount > 0 && checkedCount < $childCheckboxes.length) {
          $parentCheckbox.prop("indeterminate", true);
          $parentCheckbox.prop("checked", false);
        } else {
          $parentCheckbox.prop("indeterminate", false);
          $parentCheckbox.prop("checked", checkedCount > 0);
        }
      });
      $parentCheckbox.on("click", () => {
        $childCheckboxes.each((index, element) => {
          $(element).prop("checked", $parentCheckbox.is(":checked"));
        });
      });
    });
    $(".nested-checkboxes-detached").each((i, el) => {
      const $parentCheckbox = $(el).find('input[type="checkbox"][data-handler]');
      const $childCheckboxes = $(el).find('input[type="checkbox"]:not([data-handler])');
      $childCheckboxes.on("click", () => {
        const checkedCount = $childCheckboxes.filter(":checked").length;
        if (checkedCount > 0 && checkedCount < $childCheckboxes.length) {
          $parentCheckbox.addClass("unchecked-child");
          $parentCheckbox.prop("checked", true);
        } else if (checkedCount === $childCheckboxes.length) {
          $parentCheckbox.removeClass("unchecked-child");
          $parentCheckbox.prop("checked", true);
        } else {
          if ($parentCheckbox.is(":checked") && checkedCount < $childCheckboxes.length) {
            $parentCheckbox.addClass("unchecked-child");
            $parentCheckbox.prop("checked", true);
          } else {
            $parentCheckbox.removeClass("unchecked-child");
            $parentCheckbox.prop("checked", false);
          }
        }
      });
      $parentCheckbox.on("click", (e) => {
        $(e.currentTarget).removeClass("unchecked-child");
        $childCheckboxes.each((index, element) => {
          $(element).prop("checked", $parentCheckbox.is(":checked"));
        });
      });
    });
  });
}
